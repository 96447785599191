
export type ProjectDB = {
    id: number;
    descripcio: string;
}

export default class Project {
    id: number;
    description: string;

    constructor(id: number, description?: string) {
        this.id = id;
        this.description = description;
    }

    static fromJSON(projectJSON: ProjectDB): Project {
        return new Project(projectJSON.id, projectJSON.descripcio);
    }

    copy(): Project {
        return new Project(this.id, this.description);
    }
    
}
