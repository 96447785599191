
export type ProfileDB = {
    id: number;
    descripcio: string;
}

export default class Profile {
    id: number;
    description: string;

    constructor(id: number, description?: string) {
        this.id = id;
        this.description = description;
    }

    static fromJSON(profileJSON: ProfileDB): Profile {
        return new Profile(profileJSON.id, profileJSON.descripcio);
    }

    copy(): Profile {
        return new Profile(this.id, this.description);
    }

}