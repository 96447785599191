import React, { Component } from 'react';
import { Table, Button, Card, ButtonGroup, Form, Row, Col } from 'react-bootstrap';

import PartesListRow from './PartesListRow';

import Project from '../../model/Project';
import Profile from '../../model/Profile';
import AllocationType from '../../model/AllocationType';
import JiraIssue from '../../model/JiraIssue';
import Parte from '../../model/Parte';
import UserPrefs from '../../model/UserPrefs';

import * as DateUtils from '../../common/DateUtils';
import * as Strings from '../../common/Strings';

import './PartesList.css';


export interface Props {
    partes: Parte[];
    projects: Project[];
    profiles: Profile[];
    allocationTypes: AllocationType[];
    jiraIssues: JiraIssue[];
    userPrefs: UserPrefs;
    loadingFetch: boolean;
    loadingUpdateId?: number;
    loadingDeleteId?: number;
    onEditParte: (parte: Parte) => void;
    onDeleteParte: (parteId: number) => void;
}

interface State {
    projectFilter?: number;
    showTotalsByMonth?: boolean;
    showTotalsByWeek?: boolean;
    showTotalsByDay?: boolean;
    currentDate: Date;
    maxDate: Date;
}

export default class PartesList extends Component<Props,State> {
    constructor(props: Props) {
        super(props);
        const now = new Date();
        this.state = {
            showTotalsByMonth: false, showTotalsByWeek: false, showTotalsByDay: false,
            currentDate: new Date(now.getFullYear(), now.getMonth()),
            maxDate: new Date(now.getFullYear(), now.getMonth())
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePreviousMonth = this.handlePreviousMonth.bind(this);
        this.handleNextMonth = this.handleNextMonth.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        // Default values from userPrefs
        if (!this.props.userPrefs.equals(prevProps.userPrefs)) {
            this.setState({ showTotalsByMonth: this.props.userPrefs.defaultTotalsByMonth,
                            showTotalsByWeek: this.props.userPrefs.defaultTotalsByWeek,
                            showTotalsByDay: this.props.userPrefs.defaultTotalsByDay });
        }
    }

    // onChange
    handleInputChange(event: React.ChangeEvent) {
        const { name, value, checked } = event.currentTarget as HTMLInputElement;
        switch (name) {
            case "projectFilter": this.setState({ projectFilter: Number(value) }); break;
            case "showTotalsByMonth": this.setState({ showTotalsByMonth: checked }); break;
            case "showTotalsByWeek": this.setState({ showTotalsByWeek: checked }); break;
            case "showTotalsByDay": this.setState({ showTotalsByDay: checked }); break;
            default: break;
        }
    }

    handlePreviousMonth() {
        this.setState(state => { 
            const date: Date = new Date(state.currentDate);
            date.setMonth(date.getMonth() - 1);
            return { currentDate: date };
        });
    }

    handleNextMonth() {
        this.setState(state => {
            const date: Date = new Date(state.currentDate);
            date.setMonth(date.getMonth() + 1);
            return { currentDate: date };
        })
    }

    render() {
        const { projects, profiles, allocationTypes, jiraIssues, loadingFetch, loadingUpdateId, loadingDeleteId } = this.props;
        const { currentDate, maxDate, projectFilter, showTotalsByMonth, showTotalsByWeek, showTotalsByDay } = this.state;

        // Get partes only for this month (and filtered by project)
        const partes: Parte[] = this.props.partes.filter(parte => {
            if (parte.date.getFullYear() !== currentDate.getFullYear()) return false;
            if (parte.date.getMonth() !== currentDate.getMonth()) return false;
            if (projectFilter && parte.project.id !== projectFilter) return false;
            return true;
        });

        // Loading and empty rows
        let loadingRow: JSX.Element, emptyRow: JSX.Element;
        if (loadingFetch) {
            loadingRow =
                <tr key="loading" className="text-center">
                    <td colSpan={8} className="py-3 h5 font-weight-normal">
                        <i className="fas fa-spinner fa-pulse"></i> {Strings.PARTES_LOADING}
                    </td>
                </tr>
        }
        else if (partes.length === 0) {
            emptyRow =
                <tr key="empty" className="text-center">
                    <td colSpan={8}>{Strings.NO_PARTES}</td>
                </tr>
        }

        let dayHours = 0, weekHours = 0, monthHours = 0;
        const tableRows: JSX.Element[][] = partes.map((parte, index) => {
            let dayRow: JSX.Element, weekRow: JSX.Element, monthRow: JSX.Element;
            // Total by day row
            if (showTotalsByDay) {
                dayHours += parte.hours;
                if (index === partes.length - 1 || parte.date.getDate() !== partes[index + 1].date.getDate()) {
                    dayRow =
                        <tr key={"day" + parte.date.getDate()} className="bg-dark text-light tr-total">
                            <th colSpan={5} className="text-right">{Strings.TOTAL_BY_DAY}</th>
                            <th colSpan={3}>{dayHours}</th>
                        </tr>;
                    dayHours = 0;
                }
            }
            // Total by week row
            if (showTotalsByWeek) {
                weekHours += parte.hours;
                if (index === partes.length - 1 || DateUtils.getWeek(parte.date) !== DateUtils.getWeek(partes[index + 1].date)) {
                    weekRow = 
                        <tr key={"week" + DateUtils.getWeek(parte.date)} className="bg-dark text-light tr-total">
                            <th colSpan={5} className="text-right">{Strings.TOTAL_BY_WEEK}</th>
                            <th colSpan={3}>{weekHours}</th>
                        </tr>;
                    weekHours = 0;
                }
            }
            // Total by month row
            if (showTotalsByMonth) {
                monthHours += parte.hours;
                if (index === partes.length - 1) {
                    monthRow =
                        <tr key={"month" + parte.date.getMonth()} className="bg-dark text-light tr-total">
                            <th colSpan={5} className="text-right">{Strings.TOTAL_BY_MONTH}</th>
                            <th colSpan={3}>{monthHours}</th>
                        </tr>;
                    monthHours = 0;
                }
            }
            // Partes rows
            return [
                <PartesListRow 
                    key={parte.id} 
                    parte={parte}
                    projects={projects}
                    profiles={profiles}
                    allocationTypes={allocationTypes}
                    jiraIssues={jiraIssues}
                    loadingUpdate={loadingUpdateId === parte.id}
                    loadingDelete={loadingDeleteId === parte.id}
                    onEditParte={(parte: Parte) => this.props.onEditParte(parte)}
                    onDeleteParte={(parteId: number) => this.props.onDeleteParte(parteId)}
                />,
                dayRow,
                weekRow,
                monthRow
            ]
        });

        const projectOptions: JSX.Element[] = projects.map(project => {
            return <option key={project.id} value={project.id}>{project.description}</option>
        });
        return (
            <Card>
                <Card.Header className="table-header">
                    <Row className="align-items-center justify-content-between">
                        <Col xl="auto" className="d-none d-xl-block">
                            <h5 className="mb-0">{Strings.PARTES_LIST_HEADER}</h5>
                        </Col>
                        {/* PROJECT FILTER */}
                        <Col xs={12} sm={6} md={4} lg="auto" className="mb-3 mb-md-0 mr-lg-auto">
                            <Form.Group className="mb-0">
                                <Form.Control 
                                    as="select" name="projectFilter" size="sm"
                                    value={String(projectFilter)} onChange={this.handleInputChange}
                                >
                                    <option value="">{Strings.SELECT_ALL_PROJECTS}</option>
                                    {projectOptions}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {/* TABLE TOTALS CHECKBOXES */}
                        <Col xs={12} sm={6} md="auto" className="mb-3 mb-md-0">
                            <Form inline className="justify-content-center">
                                <h6 className="mb-0 mr-2">{Strings.TOTALS_SELECTOR}:</h6>
                                <Form.Check 
                                    inline label={Strings.TOTALS_SELECTOR_MONTH} className="w-auto"
                                    type="checkbox" name="showTotalsByMonth" id="showTotalsByMonth"
                                    checked={showTotalsByMonth || false}
                                    onChange={this.handleInputChange}
                                />
                                <Form.Check 
                                    inline label={Strings.TOTALS_SELECTOR_WEEK} className="w-auto"
                                    type="checkbox" name="showTotalsByWeek" id="showTotalsByWeek"
                                    checked={showTotalsByWeek || false}
                                    onChange={this.handleInputChange}
                                />
                                <Form.Check 
                                    inline label={Strings.TOTALS_SELECTOR_DAY} className="w-auto"
                                    type="checkbox" name="showTotalsByDay" id="showTotalsByDay"
                                    checked={showTotalsByDay || false}
                                    onChange={this.handleInputChange}
                                />
                            </Form>
                        </Col>
                        {/* MONTH SELECTOR */}
                        <Col xs={12} md="auto" className="text-center">
                            <MonthSelector
                                currentDate={currentDate}
                                maxDate={maxDate}
                                onNextMonth={() => this.handleNextMonth()}
                                onPreviousMonth={() => this.handlePreviousMonth()}
                            />
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className="p-0">
                    <Table className="bg-light mb-0" size="sm">
                        <thead className="thead-dark">
                            <tr>
                                <th>{Strings.PARTE_DAY}</th>
                                <th>{Strings.PARTE_PROJECT}</th>
                                <th>{Strings.PARTE_PROFILE}</th>
                                <th className="d-none d-sm-table-cell nowrap-lg">{Strings.PARTE_ALLOCATION_TYPE}</th>
                                <th className="d-none d-sm-table-cell">{Strings.PARTE_JIRA_ISSUE}</th>
                                <th>{Strings.PARTE_HOURS}</th>
                                <th className="d-none d-sm-table-cell">{Strings.PARTE_COMMENT}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loadingRow}
                            {emptyRow}
                            {tableRows}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}


interface MonthSelectorProps {
    currentDate: Date;
    maxDate: Date;
    onNextMonth: () => void;
    onPreviousMonth: () => void;
}

class MonthSelector extends Component<MonthSelectorProps,object> {
    render() {
        const { currentDate, maxDate } = this.props;
        return (
            <ButtonGroup className="align-items-center">
                <Button variant="light" size="sm" onClick={this.props.onPreviousMonth}>
                    <i className="fas fa-chevron-left"></i>
                </Button>
                <div className="month">
                    {Strings.MONTHS[currentDate.getMonth()]} {currentDate.getFullYear()}
                </div>
                <Button variant="light" size="sm" className="btn-next-month" onClick={this.props.onNextMonth} disabled={currentDate >= maxDate}>
                    <i className="fas fa-chevron-right"></i>
                </Button>
            </ButtonGroup>
        );
    }
}