import React, { Component } from 'react';
import { Card, Form, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ErrorModal } from '../common/Modals';

import * as Strings from '../../common/Strings';

import * as UserManager from '../../storage/UserManager';
import PartesBackend from '../../storage/PartesBackend';
import PrefsBackend from '../../storage/PrefsBackend';

import Project from '../../model/Project';
import Profile from '../../model/Profile';
import UserPrefs from '../../model/UserPrefs';


interface State {
    // Model
    userProjects: Project[];
    profiles: Profile[];
    userPrefs: UserPrefs;
    // States
    loadingStore?: boolean;
    // Result messages
    errors: string[];
}

export default class PartesPrefs extends Component<object,State> {
    partesBackend: PartesBackend = new PartesBackend();
    prefsBackend: PrefsBackend = new PrefsBackend();

    constructor(props: any) {
        super(props);
        this.state = { 
            userProjects: [], profiles: [], 
            userPrefs: new UserPrefs(undefined),
            errors: [] 
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const userNickname: string = UserManager.getUserMail().substring(0, UserManager.getUserMail().indexOf("@"));
        this.fetchPartesBackend(userNickname);
        this.fetchPrefsBackend(userNickname);
    }

    fetchPartesBackend(userNickname: string) {
        // User ID and projects
        const userIdPromise: Promise<number> = this.partesBackend.fetchUserId(userNickname);
        const projectsPromise: Promise<Project[]> = this.partesBackend.fetchProjects();
        Promise.all([userIdPromise, projectsPromise]).then(([userId, projects]) => {
            // Projects by user
            this.partesBackend.filterProjectsByUser(projects, userId)
                .then(userProjects => this.setState({ userProjects: userProjects }))
                .catch(error => this.addError(Strings.FETCH_USERPROJECTS_ERROR + ": " + error));
        })
        .catch(error => this.addError(Strings.FETCH_USERID_PROJECTS_ERROR + ": " + error));

        // Profiles
        this.partesBackend.fetchProfiles()
            .then(profiles => this.setState({ profiles: profiles }))
            .catch(error => this.addError(Strings.FETCH_PROFILES_ERROR + ": " + error));
    }

    fetchPrefsBackend(userNickname: string) {
        this.prefsBackend.fetchPrefsByUser(userNickname)
            .then(userPrefs => this.setState({ userPrefs: userPrefs }))
            .catch(error => this.addError(Strings.FETCH_USERPREFS_ERROR + ": " + error));
    }

    addError(error: any) {
        this.setState(state => ({ errors: state.errors.concat(error), loadingStore: false }));
    }
    
    // onChange
    handleInputChange(event: React.ChangeEvent) {
        const { name, value, checked } = event.currentTarget as HTMLInputElement;
        this.setState(state => { 
            let userPrefs: UserPrefs = state.userPrefs.copy();
            switch (name) {
                case "defaultProject": case "defaultProfile": case "defaultHours":
                    userPrefs[name] = (value !== "" && value !== "0") ? Number(value) : undefined; break;
                case "defaultTotalsByMonth": case "defaultTotalsByWeek": case "defaultTotalsByDay":
                    userPrefs[name] = checked; break;
                default: break;
            }
            return { userPrefs : userPrefs };
        });
    }

    // onSubmit
    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        this.storePrefs();
    }

    storePrefs() {
        this.setState({ loadingStore: true });
        this.prefsBackend.updatePrefs(this.state.userPrefs).then(() => {
            this.setState({ loadingStore: false });
            toast.success(Strings.STORE_PARTES_PREFS_SUCCESS, {className: "bg-success text-light"});
        })
        .catch(error => this.addError(Strings.STORE_PARTES_PREFS_ERROR + ": " + error));
    }

    render() {
        const { userProjects, profiles, userPrefs, loadingStore } = this.state;

        const projectOptions: JSX.Element[] = userProjects.map(project => {
            return <option key={project.id} value={project.id}>{project.description}</option>
        });
        const profileOptions: JSX.Element[] = profiles.map(profile => {
            return <option key={profile.id} value={profile.id}>{profile.description}</option>
        });

        return (
            <div>
                <Card>
                    <Card.Header>
                        <h5 className="mb-0">{Strings.PARTES_PREFS_HEADER}</h5>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>

                            <h6 className="mb-0">{Strings.PARTES_DEFAULT_VALUES_HEADER}</h6>
                            <hr className="mt-2"/>
                            <Form.Row>
                                {/* Default project */}
                                <Form.Group as={Col} xs={12} md={6} lg controlId="inputDefaultProject">
                                    <Form.Label>{Strings.PARTE_DEFAULT_PROJECT}</Form.Label>
                                    <Form.Control 
                                        as="select" name="defaultProject" 
                                        value={String(userPrefs.defaultProject)} onChange={this.handleInputChange}
                                    >
                                        <option value="">-- {Strings.SELECT_NO_PROJECT} --</option>
                                        {projectOptions}
                                    </Form.Control>
                                </Form.Group>

                                {/* Default profile */}
                                <Form.Group as={Col} xs={12} md={6} lg controlId="inputDefaultProfile">
                                    <Form.Label>{Strings.PARTE_DEFAULT_PROFILE}</Form.Label>
                                    <Form.Control 
                                        as="select" name="defaultProfile" 
                                        value={String(userPrefs.defaultProfile)} onChange={this.handleInputChange}
                                    >
                                        <option value="">-- {Strings.SELECT_NO_PROFILE} --</option>
                                        {profileOptions}
                                    </Form.Control>
                                </Form.Group>

                                {/* Default hours */}
                                <Form.Group as={Col} xs={12} md={6} lg="auto" controlId="inputDefaultHours">
                                    <Form.Label>{Strings.PARTE_DEFAULT_HOURS}</Form.Label>
                                    <Form.Control 
                                        type="number" name="defaultHours" 
                                        value={String(userPrefs.defaultHours)} onChange={this.handleInputChange}
                                        step={0.5}
                                    />
                                </Form.Group>

                                {/* Default table totals */}
                                <Form.Group as={Col} xs={12} md={6} lg="auto">
                                    <Form.Label>{Strings.PARTE_DEFAULT_TOTALS}</Form.Label>
                                    <div className="my-2">
                                        <Form.Check 
                                            inline label={Strings.TOTALS_SELECTOR_MONTH}
                                            type="checkbox" name="defaultTotalsByMonth" id="defaultTotalsByMonth"
                                            checked={userPrefs.defaultTotalsByMonth || false}
                                            onChange={this.handleInputChange}
                                        />
                                        <Form.Check 
                                            inline label={Strings.TOTALS_SELECTOR_WEEK}
                                            type="checkbox" name="defaultTotalsByWeek" id="defaultTotalsByWeek"
                                            checked={userPrefs.defaultTotalsByWeek || false}
                                            onChange={this.handleInputChange}
                                        />
                                        <Form.Check 
                                            inline label={Strings.TOTALS_SELECTOR_DAY}
                                            type="checkbox" name="defaultTotalsByDay" id="defaultTotalsByDay"
                                            checked={userPrefs.defaultTotalsByDay || false}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} lg="auto" className="mb-0 d-flex flex-column">
                                    <Button variant="primary" type="submit" disabled={loadingStore}>
                                        {loadingStore ? (
                                            <span><i className="fas fa-spinner fa-pulse"></i> {Strings.SAVE_PARTES_PREFS_BTN_LOADING}</span>
                                        ) : ( 
                                            <span><i className="fas fa-save"></i> {Strings.SAVE_PARTES_PREFS_BTN}</span>
                                        )}
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
                <ErrorModal errors={this.state.errors} onHide={() => this.setState({ errors: [] })}/>
            </div>
        );
    }
}