import * as DateUtils from '../common/DateUtils';


export type PersonalHolidayDB = {
    tecnic: string;
    dia: string;
    acceptada: number;
}

export default class PersonalHoliday {
    userId: string;
    date: Date;
    granted: boolean;

    constructor(userId: string, date: Date, granted: boolean) {
        this.userId = userId;
        this.date = date;
        this.granted = granted;
    }

    static fromJSON(personalHolidayJSON: PersonalHolidayDB): PersonalHoliday {
        return new PersonalHoliday(personalHolidayJSON.tecnic, 
                                   new Date(personalHolidayJSON.dia),
                                   !!personalHolidayJSON.acceptada);
    }

    toJSON(): PersonalHolidayDB {
        return {tecnic: this.userId, dia: DateUtils.toISODateString(this.date), acceptada: this.granted ? 1 : 0};
    }

    copy(): PersonalHoliday {
        return new PersonalHoliday(this.userId, new Date(this.date), this.granted);
    }

    equals(other: PersonalHoliday): boolean {
        return this.userId === other.userId && this.date.getTime() === other.date.getTime();
    }

}