import BackendPartesDB from './BackendPartesDB';
import * as GoogleUserManager from './GoogleUserManager';

import * as DateUtils from '../common/DateUtils';

import PersonalHoliday, { PersonalHolidayDB } from '../model/PersonalHoliday';


export default class HolidaysBackend extends BackendPartesDB {

    fetchHolidaysByUser(userId: string): Promise<PersonalHoliday[]> {
        console.log("fetchHolidaysByUser(" + userId + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"tecnic":"' + userId + '"}');
                this.axiosInst.get(encodeURI('/Vacances?filter=' + filter + '&order=dia:ASC'))
                    .then(response => {
                        const personalHolidays: PersonalHoliday[] = response.data.map((personalHoliday: PersonalHolidayDB) => { 
                            return PersonalHoliday.fromJSON(personalHoliday); 
                        });
                        resolve(personalHolidays);
                    })
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    insertHoliday(holiday: PersonalHoliday): Promise<void> {
        console.log("insertHoliday(" + holiday + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.post(encodeURI('/Vacances'), holiday.toJSON())
                    .then(() => resolve())
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    updateHoliday(holiday: PersonalHoliday): Promise<void> {
        console.log("updateHoliday(" + holiday + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const { tecnic, dia, ...holidayFields } = holiday.toJSON();
                this.axiosInst.put(encodeURI('/Vacances/' + tecnic + ',' + dia), holidayFields)
                    .then(() => resolve())
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    deleteHoliday(userId: string, date: Date): Promise<void> {
        console.log("deleteHoliday(" + userId + ", " + date + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.delete(encodeURI('/Vacances/' + userId + ',' + DateUtils.toISODateString(date)))
                    .then(() => resolve())
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

}