import React, { Component } from 'react';
import { Card, Form, Col, Button } from 'react-bootstrap';

import * as DateUtils from '../../common/DateUtils';
import * as Strings from '../../common/Strings';

import Project from '../../model/Project';
import Profile from '../../model/Profile';
import AllocationType from '../../model/AllocationType';
import JiraIssue from '../../model/JiraIssue';
import Parte from '../../model/Parte';
import UserPrefs from '../../model/UserPrefs';

import './PartesForm.scss';


export interface Props {
    projects: Project[];
    profiles: Profile[];
    allocationTypes: AllocationType[];
    jiraIssues: JiraIssue[];
    userPrefs: UserPrefs;
    loadingInsert: boolean;
    onCreateParte: (parte: Parte) => void;
}

interface State {
    parte: Parte;
    showValidation: boolean;
}

export default class PartesForm extends Component<Props,State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            parte: new Parte(new Date(), 
                             new Project(undefined),
                             new Profile(undefined),
                             new AllocationType("NI"),
                             new JiraIssue(undefined),
                             undefined, ""),
            showValidation: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        // Default values from userPrefs
        if (!this.props.userPrefs.equals(prevProps.userPrefs)) {
            this.setState(state => {
                let parte: Parte = state.parte.copy();
                parte.project.id = this.props.userPrefs.defaultProject;
                parte.profile.id = this.props.userPrefs.defaultProfile;
                parte.hours = this.props.userPrefs.defaultHours;
                return { parte: parte };
            });
        }
    }

    // onChange
    handleInputChange(event: React.ChangeEvent) {
        const { name, value } = event.currentTarget as HTMLInputElement;
        this.setState(state => {
            let parte: Parte = state.parte.copy();
            switch (name) {
                case "date": 
                    if (!value) parte.date = new Date();
                    else if (!!Date.parse(value)) parte.date = new Date(value); 
                    break;
                case "projectId": parte.project.id = Number(value); break;
                case "profileId": parte.profile.id = Number(value); break;
                case "allocationType": parte.allocationType.id = value; break;
                case "jiraIssue": parte.jiraIssue.key = value; break;
                case "hours": parte.hours = Number(value); break;
                case "comment": parte.comment = value; break;
                default: break;
            }
            return { parte: parte };
        });
    }

    // onSubmit
    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        const form: HTMLFormElement = event.currentTarget;
        if (this.validate(form)) {
            this.props.onCreateParte(this.state.parte.copy());
        }
    }

    validate(form: HTMLFormElement) {
        if (form.checkValidity()) {
            this.setState({ showValidation: false });
            return true;
        }
        // Invalid form
        else {
            for (let i: number = 0; i < form.length; i++) {
                const elem: HTMLInputElement = form[i] as HTMLInputElement;
                const errorLabel: Element = elem.parentNode.querySelector(".invalid-feedback");
                // Only check if the element can be invalid
                if (errorLabel) {
                    if (!elem.validity.valid) {
                        if (elem.validity.valueMissing) errorLabel.textContent = Strings.VALUE_MISSING;
                        else if (elem.name === "date" && elem.validity.rangeOverflow) errorLabel.textContent = Strings.DATE_VALUE_HIGH;
                        else if (elem.name === "hours" && elem.validity.rangeUnderflow) errorLabel.textContent = Strings.HOUR_VALUE_LOW;
                        else errorLabel.textContent = "";
                    }
                    else errorLabel.textContent = "";
                }
            }
            this.setState({ showValidation: true });
            return false;
        }
    }

    render() {
        const { projects, profiles, allocationTypes, jiraIssues, loadingInsert } = this.props;
        const { parte, showValidation } = this.state;

        const projectOptions: JSX.Element[] = projects.map(project => {
            return <option key={project.id} value={project.id}>{project.description}</option>
        });
        const profileOptions: JSX.Element[] = profiles.map(profile => {
            return <option key={profile.id} value={profile.id}>{profile.description}</option>
        });
        const allocationTypeOptions: JSX.Element[] = allocationTypes.filter(allocationType => allocationType.active).map(allocationType => {
            return <option key={allocationType.id} value={allocationType.id}>{allocationType.description}</option>
        });
        const jiraIssueOptions: JSX.Element[] = jiraIssues.map(jiraIssue => {
            return <option key={jiraIssue.key} value={jiraIssue.key}>{jiraIssue.key} | {jiraIssue.summary}</option>
        });

        return (
            <Card>
                <Card.Header>
                    <h5 className="mb-0">{Strings.PARTE_FORM_HEADER}</h5>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={this.handleSubmit} noValidate validated={showValidation}>
                        
                        <Form.Row>
                            {/* Date */}
                            <Form.Group as={Col} xs={12} md={6} lg className="mb-3 mb-lg-0 fg-date" controlId="inputDate">
                                <Form.Label>{Strings.PARTE_DATE}</Form.Label>
                                <Form.Control 
                                    type="date" name="date" 
                                    value={DateUtils.toISODateString(parte.date)} onChange={this.handleInputChange}
                                    required max={DateUtils.toISODateString(new Date())}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            {/* Project */}
                            <Form.Group as={Col} xs={12} md={6} lg className="mb-3 mb-lg-0 fg-project" controlId="inputProject">
                                <Form.Label>{Strings.PARTE_PROJECT}</Form.Label>
                                <Form.Control 
                                    as="select" name="projectId" 
                                    value={String(parte.project.id || "")} onChange={this.handleInputChange}
                                    required
                                >
                                    <option hidden disabled value="">{Strings.SELECT_PROJECT}</option>
                                    {projectOptions}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            {/* Profile */}
                            <Form.Group as={Col} xs={12} md={6} lg className="mb-3 mb-lg-0 fg-profile" controlId="inputProfile">
                                <Form.Label>{Strings.PARTE_PROFILE}</Form.Label>
                                <Form.Control 
                                    as="select" name="profileId" 
                                    value={String(parte.profile.id || "")} onChange={this.handleInputChange}
                                    required
                                >
                                    <option hidden disabled value="">{Strings.SELECT_PROFILE}</option>
                                    {profileOptions}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            {/* Allocation type */}
                            <Form.Group as={Col} xs={12} md={6} lg className="mb-3 mb-lg-0 fg-allocationType" controlId="inputAllocationType">
                                <Form.Label>{Strings.PARTE_ALLOCATION_TYPE}</Form.Label>
                                <Form.Control 
                                    as="select" name="allocationType" 
                                    value={parte.allocationType.id} onChange={this.handleInputChange}
                                    required
                                >
                                    {allocationTypeOptions}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            {/* Jira issue */}
                            <Form.Group as={Col} xs={12} md={6} lg className="mb-3 mb-lg-0 fg-jiraIssue" controlId="inputJiraIssue">
                                <Form.Label>{Strings.PARTE_JIRA_ISSUE}</Form.Label>
                                <Form.Control 
                                    as="select" name="jiraIssue" 
                                    value={parte.jiraIssue.key} onChange={this.handleInputChange}
                                >
                                    <option value="">{Strings.PARTE_JIRA_ISSUE_EMPTY}</option>
                                    {jiraIssueOptions}
                                </Form.Control>
                            </Form.Group>

                            {/* Hours */}
                            <Form.Group as={Col} xs={12} md={6} lg className="mb-3 mb-lg-0 fg-hours" controlId="inputHours">
                                <Form.Label>{Strings.PARTE_HOURS}</Form.Label>
                                <Form.Control 
                                    type="number" name="hours" 
                                    value={String(parte.hours)} onChange={this.handleInputChange}
                                    required min={0.5} step={0.5}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            {/* Comment */}
                            <Form.Group as={Col} xs={12} lg className="mb-3 mb-lg-0" controlId="inputComment">
                                <Form.Label>{Strings.PARTE_COMMENT}</Form.Label>
                                <Form.Control 
                                    type="text" name="comment" 
                                    value={parte.comment} onChange={this.handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={12} lg="auto" className="mb-0 d-flex flex-column justify-content-start">
                                <Form.Label className="invisible d-none d-lg-inline-block">Submit</Form.Label>
                                <Button variant="primary" type="submit" disabled={loadingInsert}>
                                    {loadingInsert ? (
                                        <span><i className="fas fa-spinner fa-pulse"></i> {Strings.INSERT_PARTE_BTN_LOADING}</span>
                                    ) : ( 
                                        <span>{Strings.INSERT_PARTE_BTN}</span>
                                    )}
                                </Button>
                            </Form.Group>
                        </Form.Row>

                    </Form>
                </Card.Body>
            </Card>
        );
    }
}