import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Strings from '../../common/Strings';

import './errors.scss';


export default class Error404 extends Component {

    render() {
        return (
            <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center">
                <h1 className="errorpage-header mt-n2 mt-sm-n3 mt-md-n4 mt-lg-n5 mb-3 mb-sm-4 mb-lg-5">
                    {Strings.ERROR_404_HEADER}
                </h1>
                <h2 className="errorpage-subheader">{Strings.ERROR_404_TITLE}</h2>
                <p className="mx-3">
                    {Strings.ERROR_404_DESCRIPTION_1}<br/>
                    {Strings.ERROR_404_DESCRIPTION_2}
                </p>
                <LinkContainer exact to="/">
                    <Button variant="primary" className="errorpage-btn mb-4">{Strings.ERROR_404_BUTTON}</Button>
                </LinkContainer>
            </div>
        );
    }

}