
export type AllocationTypeDB = {
    id: string;
    descripcio: string;
    actiu: boolean;
}

export default class AllocationType {
    id: string;
    description: string;
    active: boolean;

    constructor(id: string, description?: string, active?: boolean) {
        this.id = id;
        this.description = description;
        this.active = active;
    }

    static fromJSON(allocationTypeJSON: AllocationTypeDB): AllocationType {
        return new AllocationType(allocationTypeJSON.id, allocationTypeJSON.descripcio, allocationTypeJSON.actiu);
    }

    copy(): AllocationType {
        return new AllocationType(this.id, this.description, this.active);
    }

}