import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, Zoom } from 'react-toastify';

import * as Strings from '../../common/Strings';

import './Modals.css';


export interface ConfirmationModalProps {
    show: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export class ConfirmationModal extends Component<ConfirmationModalProps,object> {
    render() {
        const { show, message } = this.props;
        return (
            <Modal className="modal" show={show} onHide={this.props.onCancel}>
                <Modal.Header className="bg-warning text-light" closeButton>
                    <Modal.Title className="display-1 w-100 text-center">
                        <i className="far fa-exclamation-triangle"></i>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center p-4">
                    {message}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onCancel}>
                        <i className="fas fa-times"></i> {Strings.CONFIRMATION_MODAL_BTN_CANCEL}
                    </Button>
                    <Button variant="success" onClick={this.props.onConfirm}>
                        <i className="fas fa-check"></i> {Strings.CONFIRMATION_MODAL_BTN_OK}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


export interface ErrorModalProps {
    errors: string[];
    onHide: () => void;
}

export class ErrorModal extends Component<ErrorModalProps,object> {
    render() {
        const { errors } = this.props;
        const errorList: JSX.Element[] = errors.map((error, index) => {
            return <li key={index}>{error}</li>
        });

        return (
            <Modal className="modal" show={errors.length > 0} onHide={this.props.onHide}>
                <Modal.Header className="bg-danger text-light" closeButton>
                    <Modal.Title className="display-1 w-100 text-center">
                        <i className="far fa-times-circle"></i>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center p-4">
                    <h3 className="mb-3">Ooops!</h3>
                    <ul className="list-unstyled mb-2">
                        {errorList}
                    </ul>
                </Modal.Body>
            </Modal>
        );
    }
}


export class DefaultToastContainer extends Component {
    render() {
        return (
            <ToastContainer
                position="top-center"
                autoClose={3500}
                hideProgressBar={true}
                transition={Zoom}
            />
        );
    }
}