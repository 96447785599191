import React, { Component } from "react";
import { Row, Col } from 'react-bootstrap';

import Month from "./Month";

import { PublicHoliday } from "../../../model/PublicHoliday";
import PersonalHoliday from "../../../model/PersonalHoliday";


export interface Props {
    year: number;
    publicHolidays: PublicHoliday[];
    personalHolidays: PersonalHoliday[];
    selectedDays: Date[];
    onDayClick?: (month: number, day: number, selected: boolean) => void;
}

export default class Calendar extends Component<Props,object> {
    render() {
        const { year, publicHolidays, personalHolidays, selectedDays, onDayClick } = this.props;

        const months: JSX.Element[] = [0,1,2,3,4,5,6,7,8,9,10,11].map(month => {
            const monthPublicHolidays: PublicHoliday[] = publicHolidays.filter(publicHoliday => {
                return publicHoliday.date.getFullYear() === year && publicHoliday.date.getMonth() === month;
            });
            const monthPersonalHolidays: PersonalHoliday[] = personalHolidays.filter(personalHoliday => {
                return personalHoliday.date.getFullYear() === year && personalHoliday.date.getMonth() === month;
            });
            const monthSelectedDays: Date[] = selectedDays.filter(selectedDay => {
                return selectedDay.getFullYear() === year && selectedDay.getMonth() === month;
            });

            return (
                <Col key={year + "-" + month} xs={12} sm={6} md={4} lg={3}>
                    <Month 
                        year={year} 
                        month={month} 
                        publicHolidays={monthPublicHolidays}
                        personalHolidays={monthPersonalHolidays}
                        selectedDays={monthSelectedDays}
                        onDayClick={(day: number, selected: boolean) => onDayClick(month, day, selected)}
                    />
                </Col>
            );
        });

        return (
            <Row>
                {months}
            </Row>
        );
    }
}