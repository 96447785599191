import BackendPartesDB from './BackendPartesDB';
import * as GoogleUserManager from './GoogleUserManager';

import Employee, { EmployeeDB } from '../model/Employee';


export default class EmployeeBackend extends BackendPartesDB {

    fetchEmployeeByNickname(nickname: string): Promise<Employee> {
        console.log("fetchEmployeeByNickname(" + nickname + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"nickname":"' + nickname + '"}');
                this.axiosInst.get('/Tecnics?filter=' + filter)
                    .then(response => resolve(Employee.fromJSON(response.data[0])))
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

}