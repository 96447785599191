export type UserPrefDB = {
    tecnic: string;
    clau: string;
    valor: string;
}

export default class UserPrefs {
    userId: string;
    defaultProject: number;
    defaultProfile: number;
    defaultHours: number;
    defaultTotalsByMonth: boolean;
    defaultTotalsByWeek: boolean;
    defaultTotalsByDay: boolean;
    yearHolidays: object;

    constructor(userId: string, defaultProject?: number, defaultProfile?: number, defaultHours?: number,
                defaultTotalsByMonth?: boolean, defaultTotalsByWeek?: boolean, defaultTotalsByDay?: boolean,
                yearHolidays?: object) 
    {
        this.userId = userId;
        this.defaultProject = defaultProject;
        this.defaultProfile = defaultProfile;
        this.defaultHours = defaultHours;
        this.defaultTotalsByMonth = defaultTotalsByMonth;
        this.defaultTotalsByWeek = defaultTotalsByWeek;
        this.defaultTotalsByDay = defaultTotalsByDay;
        this.yearHolidays = yearHolidays !== undefined ? yearHolidays : {};
    }

    static fromJSON(userId: string, userPrefsJSON: UserPrefDB[]): UserPrefs {
        let userPrefs: UserPrefs = new UserPrefs(userId);
        userPrefs.yearHolidays = {};
        userPrefsJSON.forEach(userPrefJSON => {
            // Holidays
            if (userPrefJSON.clau.startsWith("yearHolidays")) {
                const year: string = userPrefJSON.clau.replace("yearHolidays", "");
                userPrefs.yearHolidays[year] = Number(userPrefJSON.valor);
            }
            // Partes
            switch (userPrefJSON.clau) {
                case "defaultProject": case "defaultProfile": case "defaultHours":
                    const valor: number = Number(userPrefJSON.valor);
                    userPrefs[userPrefJSON.clau] = !isNaN(valor) ? valor : undefined; 
                    break;
                case "defaultTotalsByMonth": case "defaultTotalsByWeek": case "defaultTotalsByDay":
                    userPrefs[userPrefJSON.clau] = userPrefJSON.valor === "true"; 
                    break;
                default: break;
            }
        });
        return userPrefs;
    }
    
    toJSON(): UserPrefDB[] {
        // Partes
        const userPrefsJSON: UserPrefDB[] = [
            { tecnic: this.userId, clau: "defaultProject", valor: String(this.defaultProject) },
            { tecnic: this.userId, clau: "defaultProfile", valor: String(this.defaultProfile) },
            { tecnic: this.userId, clau: "defaultHours", valor: String(this.defaultHours) },
            { tecnic: this.userId, clau: "defaultTotalsByMonth", valor: String(this.defaultTotalsByMonth) },
            { tecnic: this.userId, clau: "defaultTotalsByWeek", valor: String(this.defaultTotalsByWeek) },
            { tecnic: this.userId, clau: "defaultTotalsByDay", valor: String(this.defaultTotalsByDay) },
        ];
        // Holidays
        for (let key in this.yearHolidays) {
            userPrefsJSON.push({ tecnic: this.userId, clau: "yearHolidays" + key, valor: String(this.yearHolidays[key]) });
        }
        return userPrefsJSON;        
    }
    
    copy(): UserPrefs {
        return new UserPrefs(this.userId, this.defaultProject, this.defaultProfile, this.defaultHours,
                             this.defaultTotalsByMonth, this.defaultTotalsByWeek, this.defaultTotalsByDay,
                             {...this.yearHolidays});
    }

    equals(other: UserPrefs): boolean {
        return this.userId === other.userId && this.defaultProject === other.defaultProject &&
               this.defaultProfile === other.defaultProfile && this.defaultHours === other.defaultHours &&
               this.defaultTotalsByMonth === other.defaultTotalsByMonth && this.defaultTotalsByWeek === other.defaultTotalsByWeek &&
               this.defaultTotalsByDay === other.defaultTotalsByDay && this.yearHolidays === other.yearHolidays;
    }

}