import React, { Component } from 'react';
import { Button, Card, Collapse } from 'react-bootstrap';

import * as Strings from '../../common/Strings';

import './errors.scss';


interface State {
    error: any;
    errorInfo: any;
    showErrorDetails: boolean;
}

export default class ErrorBoundary extends Component<object,State> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null, showErrorDetails: false };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    } 
  
    render() {
        const { error, errorInfo, showErrorDetails } = this.state;
        if (errorInfo) {
            // Error path
            const componentStack: JSX.Element[] = errorInfo.componentStack.split("\n    ").map((stackElement, index) => {
                return <li key={index}>{stackElement}</li>;
            });
            return (
                <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center">
                    <h1 className="errorpage-header mt-n2 mt-sm-n3 mt-md-n4 mb-2 mb-sm-3 mb-lg-4">
                        {Strings.FATAL_ERROR_HEADER}
                    </h1>
                    <h2 className="errorpage-subheader">{Strings.FATAL_ERROR_TITLE}</h2>
                    <p className="mx-3">{Strings.FATAL_ERROR_DESCRIPTION}</p>

                    <Button 
                        variant="primary" className="errorpage-btn" 
                        onClick={() => this.setState({ showErrorDetails: !showErrorDetails })}
                    >
                        {showErrorDetails ? Strings.FATAL_ERROR_HIDE_DETAILS_BUTTON : Strings.FATAL_ERROR_SHOW_DETAILS_BUTTON}
                    </Button>
                    <Collapse in={showErrorDetails}>
                        <Card className="text-left mt-2 mb-4 mx-1">
                            <Card.Body className="text-danger">
                                <p><strong>{error && error.toString()}</strong></p>
                                <ul className="list-unstyled mb-0 pl-3">{componentStack}</ul>
                            </Card.Body>
                        </Card>
                    </Collapse>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }  

}