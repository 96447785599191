import React, { Component } from "react";
import { Table } from 'react-bootstrap';

import Day from "./Day";

import * as Strings from '../../../common/Strings';

import { PublicHoliday } from "../../../model/PublicHoliday";
import PersonalHoliday from "../../../model/PersonalHoliday";


export interface Props {
    year: number;
    month: number;
    publicHolidays?: PublicHoliday[];
    personalHolidays?: PersonalHoliday[];
    selectedDays: Date[];
    onDayClick?: (day: number, selected: boolean) => void;
}

export default class Month extends Component<Props,object> {
    render() {
        const { year, month, publicHolidays, personalHolidays, selectedDays, onDayClick } = this.props;
        let date: Date = new Date(Date.UTC(year, month, 1));

        let days: JSX.Element[] = [];
        let daysOfWeek: JSX.Element[] = [];
        let week: number = 1;

        while (date.getMonth() === month) {
            // Monday-based day of week
            const dayOfWeek: number = date.getDay() - 1 >= 0 ? date.getDay() - 1 : 6;
            // Empty cells at the start of the month
            if (daysOfWeek.length === 0) {
                for (let i = 0; i < dayOfWeek; i++) {
                    daysOfWeek.push(<td key={"empty" + i}></td>);
                }
            }
            // Day cells (grouped by week)
            const day: number = date.getDate();
            daysOfWeek.push(
                <Day
                    key={day}
                    date={new Date(date)} 
                    dayOfWeek={dayOfWeek} 
                    publicHoliday={publicHolidays.find(holiday => holiday.date.getTime() === date.getTime())}
                    personalHoliday={personalHolidays.find(holiday => holiday.date.getTime() === date.getTime())}
                    selected={!!selectedDays.find(selectedDay => selectedDay.getTime() === date.getTime())}
                    onClick={(selected: boolean) => onDayClick(day, selected)}
                />
            );
            if (dayOfWeek === 6) {
                days.push(<tr key={week}>{daysOfWeek}</tr>);
                daysOfWeek = [];
                week++;
            }
            // Last week
            date.setDate(date.getDate() + 1);
            if (date.getMonth() !== month && daysOfWeek.length > 0) {
                days.push(<tr key={week}>{daysOfWeek}</tr>);
            }
        }

        return (
            <Table size="sm" className="table-borderless text-center">
                <thead>
                    <tr>
                        <th colSpan={8}>{Strings.MONTHS[month]}</th>
                    </tr>
                    <tr>
                        {Strings.DAYS_OF_WEEK_ABBR.map(dayOfWeek => { return <th key={dayOfWeek}>{dayOfWeek}</th> })}
                    </tr>
                </thead>
                <tbody>
                    {days}
                </tbody>
            </Table>
        );
    }
}
