import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import LoginPage from './components/login/LoginPage';
import PartesPage from './components/partes/PartesPage';
import HolidaysPage from './components/holidays/HolidaysPage';
import PreferencesPage from './components/preferences/PreferencesPage';
import ErrorBoundary from './components/errors/ErrorBoundary';
import Error404 from './components/errors/404';

import * as GoogleUserManager from './storage/GoogleUserManager';
import * as UserManager from './storage/UserManager';

import './bootstrap-custom.scss';
import './assets/fontawesome/css/all.css';


interface State {
    ready: boolean;
}

export default class App extends Component<object,State> {
    constructor(props: any) {
        super(props);
        this.state = { ready: false };
    }

    componentDidMount() {
        GoogleUserManager.setup(this).then(() => {
            this.setState({ ready: true });
        })
        .catch(error => {
            console.log("GoogleUserManager SETUP ERROR");
            console.log(error);
            this.setState({ ready: true });
        });
    }

    render() {
        const { ready } = this.state;
        return (
            ready &&
            <ErrorBoundary>
                <Router>
                    <Switch>
                        <Redirect exact from="/" to="/partes"/>
                        <PrivateRoute 
                            path="/login" redirectPath="/partes" 
                            condition={!UserManager.isSignedIn()} 
                            component={LoginPage}
                        />
                        <PrivateRoute 
                            path="/partes" redirectPath="/login" 
                            condition={UserManager.isSignedIn()}
                            component={PartesPage}
                        />
                        <PrivateRoute 
                            path="/holidays" redirectPath="/login" 
                            condition={UserManager.isSignedIn()}
                            component={HolidaysPage}
                        />
                        <PrivateRoute 
                            path="/preferences" redirectPath="/login" 
                            condition={UserManager.isSignedIn()}
                            component={PreferencesPage}
                        />
                        {/* 404 Error */}
                        <Route path="/" component={Error404}/>
                    </Switch>
                </Router>
            </ErrorBoundary>
        );
    }
}


interface PrivateRouteProps {
    path: string;
    redirectPath: string;
    condition: boolean;
    component: any;
}

function PrivateRoute({ path, condition, redirectPath, component: Component }: PrivateRouteProps) {
    return (
        <Route
            path={path}
            render={(props: any) =>
                condition ? <Component {...props} /> : <Redirect to={redirectPath}/>
            }
        />
    );
}