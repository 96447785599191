import axios, { AxiosInstance } from 'axios';
import Backend from './Backend';

import * as UserManager from './UserManager';


export default class BackendPartesDB extends Backend {
    axiosInst: AxiosInstance = axios.create({
        baseURL: "https://dbapi.csb-bi.net/partes"
    });

    setAuthorizationHeader(): void {
        this.axiosInst.defaults.headers.common["Authorization"] = "Bearer " + UserManager.getUserToken();
    }

}