import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import CSBNavbar from '../common/CSBNavbar';
import PartesForm from './PartesForm';
import PartesList from './PartesList';
import Footer from '../common/Footer';
import { ConfirmationModal, ErrorModal, DefaultToastContainer } from '../common/Modals';

import * as Strings from '../../common/Strings';

import * as UserManager from '../../storage/UserManager';
import PartesBackend from '../../storage/PartesBackend';
import JiraBackend from '../../storage/JiraBackend';
import PrefsBackend from '../../storage/PrefsBackend';

import Project from '../../model/Project';
import Profile from '../../model/Profile';
import AllocationType from '../../model/AllocationType';
import JiraIssue from '../../model/JiraIssue';
import Parte from '../../model/Parte';
import UserPrefs from '../../model/UserPrefs';

import '../common/common.css';
import 'react-toastify/dist/ReactToastify.min.css';


interface State {
    // Model
    userId?: number;
    projects: Project[];
    userProjects: Project[];
    profiles: Profile[];
    allocationTypes: AllocationType[];
    jiraIssues: JiraIssue[];
    partes: Parte[];
    userPrefs: UserPrefs;
    // States
    loadingFetch: boolean;
    loadingInsert: boolean;
    loadingUpdateId?: number;
    confirmDeleteId?: number;
    loadingDeleteId?: number;
    // Result messages
    errors: string[];
}

export default class PartesPage extends Component<object,State> {
    partesBackend: PartesBackend = new PartesBackend();
    jiraBackend: JiraBackend = new JiraBackend();
    prefsBackend: PrefsBackend = new PrefsBackend();

    constructor(props: any) {
        super(props);
        this.state = {
            projects: [], userProjects: [], profiles: [], allocationTypes: [], jiraIssues: [], partes: [],
            userPrefs: new UserPrefs(undefined),
            loadingFetch: true, loadingInsert: false,
            errors: []
        }
    }

    componentDidMount() {
        const userNickname: string = UserManager.getUserNickname();
        this.fetchPartesBackend(userNickname);
        this.fetchPrefsBackend(userNickname);
    }

    fetchPartesBackend(userNickname: string) {
        // User ID, projects and profiles
        const userIdPromise: Promise<number> = this.partesBackend.fetchUserId(userNickname);
        const projectsPromise: Promise<Project[]> = this.partesBackend.fetchProjects();
        const profilesPromise: Promise<Profile[]> = this.partesBackend.fetchProfiles();
        const allocationTypesPromise: Promise<AllocationType[]> = this.partesBackend.fetchAllocationTypes();
        const jiraIssuesPromise: Promise<JiraIssue[]> = this.jiraBackend.fetchJiraIssues();
        Promise.all([userIdPromise, projectsPromise, profilesPromise, allocationTypesPromise, jiraIssuesPromise])
            .then(([userId, projects, profiles, allocationTypes, jiraIssues]) => {
                this.setState({ userId: userId, projects: projects, profiles: profiles, allocationTypes: allocationTypes, jiraIssues: jiraIssues });
                // Projects by user and partes
                const projectsByUserPromise: Promise<Project[]> = this.partesBackend.filterProjectsByUser(projects, userId);
                const partesPromise: Promise<Parte[]> = this.partesBackend.fetchPartesByUser(userId);
                Promise.all([projectsByUserPromise, partesPromise]).then(([userProjects, fetchedPartes]) => {
                    const partes: Parte[] = fetchedPartes.map(parte => {
                        parte.fillDescriptions(projects, profiles, allocationTypes, jiraIssues);
                        return parte;
                    });
                    this.setState({ userProjects: userProjects, partes: partes, loadingFetch: false });
                })
                .catch(error => this.addError(Strings.FETCH_USERPROJECTS_PARTES_ERROR + ": " + error));
        })
        .catch(error => this.addError(Strings.FETCH_USERID_PROJECTS_PROFILES_ERROR + ": " + error));
    }

    fetchPrefsBackend(userNickname: string) {
        this.prefsBackend.fetchPrefsByUser(userNickname)
            .then(userPrefs => this.setState({ userPrefs: userPrefs }))
            .catch(error => this.addError(Strings.FETCH_USERPREFS_ERROR + ": " + error));
    }

    addError(error: any) {
        this.setState(state => {
            const errors: string[] = state.errors.concat(error);
            return { errors: errors, loadingFetch: false, loadingInsert: false, 
                     loadingUpdateId: undefined, loadingDeleteId: undefined };
        });
    }

    // CREATE PARTE
    handleCreateParte(parte: Parte) {
        this.setState({ loadingInsert: true });
        parte.userId = this.state.userId;
        this.partesBackend.fetchMaxParteId().then(parteId => {
            parte.id = parteId + 1;
            this.partesBackend.insertParte(parte)
                .then(() => this.addParte(parte))
                .catch(error => this.addError(Strings.INSERT_PARTE_ERROR + ": " + error));
        })
        .catch(error => this.addError(Strings.INSERT_PARTE_ERROR + ": " + error));
    }

    addParte(parte: Parte) {
        const { projects, profiles, allocationTypes, jiraIssues, partes } = this.state;
        parte.fillDescriptions(projects, profiles, allocationTypes, jiraIssues);
        // Insert parte at correct index (based by date)
        let indexToInsert: number;
        for (indexToInsert = 0; indexToInsert < partes.length; indexToInsert++) {
            if (parte.date >= partes[indexToInsert].date) break;
        }
        let newPartes: Parte[] = [...partes];
        newPartes.splice(indexToInsert, 0, parte);
        this.setState({ partes: newPartes, loadingInsert: false });

        toast.success(Strings.INSERT_PARTE_SUCCESS, {className: "bg-success text-light"});
    }

    // EDIT PARTE
    handleEditParte(parte: Parte) {
        this.setState({ loadingUpdateId: parte.id });
        this.partesBackend.updateParte(parte)
            .then(() => this.updateParte(parte))
            .catch(error => this.addError(Strings.UPDATE_PARTE_ERROR + ": " + error));
    }

    updateParte(updatedParte: Parte) {
        const { projects, profiles, allocationTypes, jiraIssues, partes } = this.state;
        updatedParte.fillDescriptions(projects, profiles, allocationTypes, jiraIssues);
        // Insert parte at correct index (based by date)
        let newPartes: Parte[] = partes.filter(parte => { return parte.id !== updatedParte.id });
        let indexToInsert: number;
        for (indexToInsert = 0; indexToInsert < partes.length; indexToInsert++) {
            if (updatedParte.date >= newPartes[indexToInsert].date) break;
        }
        newPartes.splice(indexToInsert, 0, updatedParte);
        this.setState({ partes: newPartes, loadingUpdateId: undefined });

        toast.success(Strings.UPDATE_PARTE_SUCCESS, {className: "bg-success text-light"});
    }

    // DELETE PARTE
    handleDeleteParte(parteId: number) {
        this.setState({ confirmDeleteId: undefined, loadingDeleteId: parteId });
        this.partesBackend.deleteParte(parteId)
            .then(() => this.deleteParte(parteId))
            .catch(error => this.addError(Strings.DELETE_PARTE_ERROR + ": " + error));
    }

    deleteParte(parteId: number) {
        const partes: Parte[] = this.state.partes.filter(parte => parte.id !== parteId);
        this.setState({ partes: partes, loadingDeleteId: undefined });
        toast.success(Strings.DELETE_PARTE_SUCCESS, {className: "bg-success text-light"});
    }

    render() {
        return (
            <div className="min-vh-100 d-flex flex-column">
                <CSBNavbar/>
                <Container fluid className="bg-csb d-flex flex-column flex-grow-1">
                    <Row className="my-2 my-md-3 my-lg-4">
                        <Col className="px-1 px-md-2 px-lg-3">
                            <PartesForm
                                projects={this.state.userProjects}
                                profiles={this.state.profiles}
                                allocationTypes={this.state.allocationTypes}
                                jiraIssues={this.state.jiraIssues}
                                userPrefs={this.state.userPrefs}
                                loadingInsert={this.state.loadingInsert}
                                onCreateParte={(parte: Parte) => this.handleCreateParte(parte)}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2 mb-md-3 mb-lg-4">
                        <Col className="px-1 px-md-2 px-lg-3">
                            <PartesList
                                partes={this.state.partes}
                                projects={this.state.userProjects}
                                profiles={this.state.profiles}
                                allocationTypes={this.state.allocationTypes}
                                jiraIssues={this.state.jiraIssues}
                                userPrefs={this.state.userPrefs}
                                loadingFetch={this.state.loadingFetch}
                                loadingUpdateId={this.state.loadingUpdateId}
                                loadingDeleteId={this.state.loadingDeleteId}
                                onEditParte={(parte: Parte) => this.handleEditParte(parte)}
                                onDeleteParte={(parteId: number) => this.setState({ confirmDeleteId: parteId })}
                            />
                        </Col>
                    </Row>
                </Container>
                <Footer/>

                {/* NOTIFICATIONS (Success, confirmation and error) */}
                <DefaultToastContainer/>
                <ConfirmationModal 
                    show={this.state.confirmDeleteId !== undefined} 
                    message={Strings.DELETE_PARTE_CONFIRMATION}
                    onConfirm={() => this.handleDeleteParte(this.state.confirmDeleteId)} 
                    onCancel={() => this.setState({ confirmDeleteId: undefined })}
                />
                <ErrorModal errors={this.state.errors} onHide={() => this.setState({ errors: [] })}/>
            </div>
        );
    }
}