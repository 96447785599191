import * as Constants from '../common/Constants';
import * as UserManager from './UserManager';


export const setup = (componentToUpdate?: React.Component): Promise<void> => {
    return new Promise((resolve, reject) => {
        gapi.load("auth2", () => {
            gapi.auth2.init({ "client_id": Constants.GOOGLE_CLIENT_ID })
                .then((auth2: gapi.auth2.GoogleAuth) => {
                    // Initial check
                    if (auth2.isSignedIn.get()) {
                        console.log("SIGNED IN. Es guarda l'usuari a la LocalStorage");
                        const user: gapi.auth2.GoogleUser = auth2.currentUser.get();
                        storeUser(user);
                    }
                    else {
                        console.log("NOT SIGNED IN. S'esborra l'usuari de la LocalStorage");
                        clearUser();
                    }

                    // Listener setup
                    auth2.currentUser.listen((user: gapi.auth2.GoogleUser) => {
                        if (auth2.isSignedIn.get()) {
                            console.log("NOW SIGNED IN. Es guarda l'usuari a la LocalStorage");
                            storeUser(user);
                        }
                        else {
                            console.log("NOW NOT SIGNED IN. S'esborra l'usuari de la LocalStorage");
                            clearUser();
                        }
                        componentToUpdate.forceUpdate();
                    });

                    resolve();
                })
                .catch((err: any) => reject(err));
        });
    });
}

export const reloadUserTokenIfExpired = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        // Expired token!
        const userTokenExp = UserManager.getUserTokenExpiration();
        if (userTokenExp === null || new Date().getTime() > userTokenExp) {
            console.log("Expired token, reloading from Google");
            gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
                .then((authResponse: gapi.auth2.AuthResponse) => {
                    storeUserToken(authResponse);
                    resolve();
                })
                .catch(err => reject(err));
        }
        // Valid token
        else resolve();
    });
}


const storeUser = (user: gapi.auth2.GoogleUser) => {
    UserManager.storeUser(user.getBasicProfile().getEmail(), 
                          user.getBasicProfile().getName(), 
                          user.getBasicProfile().getImageUrl(),
                          user.getAuthResponse().id_token, 
                          user.getAuthResponse().expires_at);
}

const storeUserToken = (authResponse: gapi.auth2.AuthResponse) => {
    UserManager.storeUserToken(authResponse.id_token, authResponse.expires_at);
}

const clearUser = () => {
    UserManager.clearUser();
}