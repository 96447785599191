
export type EmployeeDB = {
    id: number;
    payslips_url: string;
}

export default class Employee {
    id: number;
    payslipsUrl: string;

    constructor(id: number, payslipsUrl?: string) {
        this.id = id;
        this.payslipsUrl = payslipsUrl;
    }

    static fromJSON(employeeJSON: EmployeeDB): Employee {
        return new Employee(employeeJSON.id, employeeJSON.payslips_url);
    }
    
    copy(): Employee {
        return new Employee(this.id, this.payslipsUrl);
    }
    
}