import React, { Component } from 'react';
import { Card, Form, Col, Button, ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as Strings from '../../common/Strings';

import * as UserManager from '../../storage/UserManager';
import PrefsBackend from '../../storage/PrefsBackend';

import UserPrefs from '../../model/UserPrefs';


interface State {
    // Model
    minYear: number;
    userPrefs: UserPrefs;
    // States
    loadingStore?: boolean;
    // Result messages
    errors: string[];
}

export default class HolidaysPrefs extends Component<object,State> {
    prefsBackend: PrefsBackend = new PrefsBackend();

    constructor(props: any) {
        super(props);
        this.state = {
            minYear: 2019,
            userPrefs: new UserPrefs(undefined),
            errors: []
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMoreYearsClick = this.handleMoreYearsClick.bind(this);
        this.handleLessYearsClick = this.handleLessYearsClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const userNickname: string = UserManager.getUserMail().substring(0, UserManager.getUserMail().indexOf("@"));
        this.fetchPrefsBackend(userNickname);
    }

    fetchPrefsBackend(userNickname: string) {
        const { minYear } = this.state;
        this.prefsBackend.fetchPrefsByUser(userNickname)
            .then(userPrefs => {
                if (!userPrefs.yearHolidays[minYear]) userPrefs.yearHolidays[minYear] = 23;
                this.setState({ userPrefs: userPrefs });
            })
            .catch(error => this.addError(Strings.FETCH_USERPREFS_ERROR + ": " + error));
    }

    addError(error: any) {
        this.setState(state => ({ errors: state.errors.concat(error), loadingStore: false }));
    }

    getMaxYear(): number {
        const { minYear, userPrefs } = this.state;
        let maxYear: number = minYear;
        for (let year in userPrefs.yearHolidays) {
            if (userPrefs.yearHolidays[year] && Number(year) > maxYear) maxYear = Number(year);
        }
        return maxYear;
    }

    // onChange
    handleInputChange(event: React.ChangeEvent) {
        const { name, value } = event.currentTarget as HTMLInputElement;
        this.setState(state => {
            let userPrefs: UserPrefs = state.userPrefs.copy();
            const year: string = name.replace("yearHolidays", "");
            userPrefs.yearHolidays[year] = value !== "" ? Number(value) : undefined;
            return { userPrefs: userPrefs };
        });
    }

    handleMoreYearsClick() {
        this.setState(state => { 
            let userPrefs: UserPrefs = state.userPrefs.copy();
            userPrefs.yearHolidays[this.getMaxYear() + 1] = 23;
            return { userPrefs: userPrefs }; 
        });
    }

    handleLessYearsClick() {
        this.setState(state => { 
            let userPrefs: UserPrefs = state.userPrefs.copy();
            userPrefs.yearHolidays[this.getMaxYear()] = undefined;
            return { userPrefs: userPrefs }; 
        });
    }

    // onSubmit
    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        this.storePrefs();
    }

    storePrefs() {
        this.setState({ loadingStore: true });
        this.prefsBackend.updatePrefs(this.state.userPrefs).then(() => {
            this.setState({ loadingStore: false });
            toast.success(Strings.STORE_HOLIDAYS_PREFS_SUCCESS, {className: "bg-success text-light"});
        })
        .catch(error => this.addError(Strings.STORE_HOLIDAYS_PREFS_ERROR + ": " + error));
    }

    render() {
        const { minYear, userPrefs, loadingStore } = this.state;

        let yearHolidaysInputs: JSX.Element[] = [];
        for (let year: number = minYear; year <= this.getMaxYear(); year++) {
            yearHolidaysInputs.push(
                <Form.Group as={Col} xs={12} sm={4} md={3} lg={2} key={"inputYearHolidays" + year} controlId={"inputYearHolidays" + year}>
                    <Form.Label>{year}</Form.Label>
                    <Form.Control 
                        type="number" name={"yearHolidays" + year}
                        value={String(userPrefs.yearHolidays[year])} onChange={this.handleInputChange}
                        step={1}
                    />
                </Form.Group>
            );
        }

        return (
            <Card>
                <Card.Header>
                    <h5 className="mb-0">{Strings.HOLIDAYS_PREFS_HEADER}</h5>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={this.handleSubmit}>
                        <h6 className="mb-0">{Strings.YEAR_HOLIDAYS_HEADER}</h6>
                        <hr className="mt-2"/>

                        <Form.Row>
                            {/* Year holidays */}
                            {yearHolidaysInputs}
                            <Form.Group as={Col} xs={12} sm="auto" className="d-flex flex-column">
                                <Form.Label className="invisible d-none d-sm-inline-block">+</Form.Label>
                                <ButtonGroup>
                                    <Button variant="success" type="button" onClick={this.handleMoreYearsClick}>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                    <Button 
                                        variant="warning" type="button" className="text-light" 
                                        onClick={this.handleLessYearsClick} disabled={this.getMaxYear() <= minYear}
                                    >
                                        <i className="fas fa-minus"></i>
                                    </Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} lg="auto" className="mb-0 d-flex flex-column">
                                <Button variant="primary" type="submit" disabled={loadingStore}>
                                    {loadingStore ? (
                                        <span><i className="fas fa-spinner fa-pulse"></i> {Strings.SAVE_HOLIDAYS_PREFS_BTN_LOADING}</span>
                                    ) : ( 
                                        <span><i className="fas fa-save"></i> {Strings.SAVE_HOLIDAYS_PREFS_BTN}</span>
                                    )}
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    
                </Card.Body>
            </Card>
        );
    }
}