import React, { Component } from 'react';

import './Footer.css';


export default class Footer extends Component {
    render() {
        return (
            <footer className="text-light text-center footer-csb">
                <small>&copy; 2019 <a href="http://csb-bi.net" target="_blank" rel="noopener noreferrer">Computer Sciences Brand</a></small>
            </footer>
        );
    }
}