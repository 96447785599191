import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import CSBNavbar from '../common/CSBNavbar';
import Footer from '../common/Footer';
import { DefaultToastContainer } from '../common/Modals';

import PartesPrefs from './PartesPrefs';
import HolidaysPrefs from './HolidaysPrefs';

import '../common/common.css';
import 'react-toastify/dist/ReactToastify.min.css';


export default class PreferencesPage extends Component {
    render() {
        return (
            <div className="min-vh-100 d-flex flex-column">
                <CSBNavbar/>

                <Container fluid className="bg-csb d-flex flex-column flex-grow-1">
                    <Row className="my-2 my-md-3 my-lg-4">
                        <Col className="px-1 px-md-2 px-lg-3">
                            <PartesPrefs/>
                        </Col>
                    </Row>
                    <Row className="mb-2 mb-md-3 mb-lg-4">
                        <Col className="px-1 px-md-2 px-lg-3">
                            <HolidaysPrefs/>
                        </Col>
                    </Row>
                </Container>

                <Footer/>

                <DefaultToastContainer/>
            </div>
        );
    }
}