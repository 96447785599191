import * as Constants from '../common/Constants';


export const getUserMail = (): string => {
    return localStorage.getItem(Constants.LS_USER_MAIL);
}

export const getUserNickname = (): string => {
    const userMail: string = getUserMail();
    return userMail ? userMail.substring(0, userMail.indexOf("@")) : null;
}

export const getUserName = (): string => {
    return localStorage.getItem(Constants.LS_USER_NAME);
}

export const getUserImageUrl = (): string => {
    return localStorage.getItem(Constants.LS_USER_IMAGE_URL);
}

export const getUserToken = (): string => {
    return localStorage.getItem(Constants.LS_USER_TOKEN);
}

export const getUserTokenExpiration = (): number => {
    return Number(localStorage.getItem(Constants.LS_USER_TOKEN_EXP));
}

export const isSignedIn = (): boolean => {
    return localStorage.getItem(Constants.LS_USER_MAIL) != undefined;
}


export const storeUser = (userMail: string, userName: string, userImageUrl: string, userToken: string, userTokenExp: number) => {
    localStorage.setItem(Constants.LS_USER_MAIL, userMail);
    localStorage.setItem(Constants.LS_USER_NAME, userName);
    localStorage.setItem(Constants.LS_USER_IMAGE_URL, userImageUrl);
    storeUserToken(userToken, userTokenExp);
}

export const storeUserToken = (userToken: string, userTokenExp: number) => {
    localStorage.setItem(Constants.LS_USER_TOKEN, userToken);
    localStorage.setItem(Constants.LS_USER_TOKEN_EXP, String(userTokenExp));
}

export const clearUser = () => {
    localStorage.clear();
}