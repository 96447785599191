
export type JiraIssueDB = {
    key: string;
    summary: string;
}

export default class JiraIssue {
    key: string;
    summary: string;

    constructor(key: string, summary?: string) {
        this.key = key;
        this.summary = summary;
    }

    static fromJSON(jiraIssueJSON: JiraIssueDB): JiraIssue {
        return new JiraIssue(jiraIssueJSON.key, jiraIssueJSON.summary);
    }

    copy(): JiraIssue {
        return new JiraIssue(this.key, this.summary);
    }

}