import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import CSBNavbar from '../common/CSBNavbar';
import LoginCard from './LoginCard';
import Footer from '../common/Footer';

import '../common/common.css';


class LoginPage extends Component<RouteComponentProps,object> {
    render() {
        return (
            <div className="min-vh-100 d-flex flex-column">
                <CSBNavbar hideLinks={this.props.location.pathname === "/login"}/>

                <Container fluid className="bg-csb d-flex flex-column flex-grow-1 justify-content-center">
                    <Row className="justify-content-center">
                        <Col xs={11} sm={8} md={6} lg={5} xl={4}>
                            <LoginCard/>
                        </Col>
                    </Row>
                </Container>

                <Footer/>
            </div>
        );
    }
}

export default LoginPage;