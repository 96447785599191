import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { PublicHoliday } from "../../../model/PublicHoliday";
import PersonalHoliday from "../../../model/PersonalHoliday";

import './Day.css';


export interface Props {
    date: Date;
    dayOfWeek: number;
    publicHoliday?: PublicHoliday;
    personalHoliday?: PersonalHoliday;
    selected?: boolean;
    onClick?: (selected: boolean) => void;
}

export default class Day extends Component<Props,object> {
    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { dayOfWeek, publicHoliday, selected } = this.props;
        if (dayOfWeek < 5 && !publicHoliday) {
            this.props.onClick(!selected);
        }
    }

    render() {
        const { date, dayOfWeek, publicHoliday, personalHoliday, selected } = this.props;
        let className: string = "";
        // Day type (public holiday, weekend, personal holiday)
        if (publicHoliday) className = " bg-warning text-dark";
        else if (dayOfWeek >= 5) className = " bg-danger text-light";
        else if (personalHoliday) {
            if (personalHoliday.granted) className = " bg-success text-light";
            else className = " bg-info text-light";
        }
        
        // Day selection
        if (selected) className += " selected";
        
        // Today
        const today: Date = new Date();
        if (date.getTime() === Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) className += " today";

        return (
            publicHoliday ?
                <OverlayTrigger 
                    overlay={<Tooltip id={"tooltip-" + publicHoliday.date.toISOString()}>{publicHoliday.description}</Tooltip>}
                >
                    <td className={"td-day" + className} onClick={this.handleClick}>
                        <span className="position-relative">{date.getDate()}</span>
                    </td>
                </OverlayTrigger>
            :
                <td className={"td-day" + className} onClick={this.handleClick}>
                    <span className="position-relative">{date.getDate()}</span>
                </td>
        );
    }
}