import React, { Component } from 'react';
import { Container, Row, Col, Card, ButtonGroup, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import ICAL from 'ical.js';

import CSBNavbar from '../common/CSBNavbar';
import Calendar from './calendar/Calendar';
import Footer from '../common/Footer';
import { ErrorModal, DefaultToastContainer } from '../common/Modals';

import * as Strings from '../../common/Strings';

import HolidaysBackend from '../../storage/HolidaysBackend';
import PrefsBackend from '../../storage/PrefsBackend';
import * as UserManager from '../../storage/UserManager';
import { PublicHoliday } from '../../model/PublicHoliday';
import PersonalHoliday from '../../model/PersonalHoliday';
import UserPrefs from '../../model/UserPrefs';

import '../common/common.css';
import './HolidaysPage.css';
import 'react-toastify/dist/ReactToastify.min.css';


interface State {
    userId?: string;
    year: number;
    publicHolidays: PublicHoliday[];
    personalHolidays: PersonalHoliday[];
    selectedDays: Date[];
    userPrefs: UserPrefs;
    // States
    loadingSetRequestedHolidays?: boolean;
    loadingSetGrantedHolidays?: boolean;
    loadingDeleteHolidays?: boolean;
    // Result messages
    errors: string[];
}

export default class HolidaysPage extends Component<object,State> {
    holidaysBackend: HolidaysBackend = new HolidaysBackend();
    prefsBackend: PrefsBackend = new PrefsBackend();

    constructor(props: any) {
        super(props);
        this.state = {
            year: new Date().getFullYear(),
            publicHolidays: [],
            personalHolidays: [],
            selectedDays: [],
            userPrefs: new UserPrefs(undefined),
            errors: []
        }
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleSetRequestedHolidays = this.handleSetRequestedHolidays.bind(this);
        this.handleSetGrantedHolidays = this.handleSetGrantedHolidays.bind(this);
        this.handleDeleteHolidays = this.handleDeleteHolidays.bind(this);
        this.handlePreviousYear = this.handlePreviousYear.bind(this);
        this.handleNextYear = this.handleNextYear.bind(this);
    }

    componentDidMount() {
        const userNickname: string = UserManager.getUserMail().substring(0, UserManager.getUserMail().indexOf("@"));
        this.setState({ userId: userNickname });

        this.fetchPublicHolidays(this.state.year);
        this.fetchHolidaysBackend(userNickname);
        this.fetchPrefsBackend(userNickname);
    }

    fetchPublicHolidays(year: number) {
        let workingCalendar: string;
        try { workingCalendar = require("../../assets/workingcalendar_" + year + ".ics"); }
        catch (exception) {}

        if (workingCalendar) {
            axios.get(workingCalendar).then(response => {
                const jcalData = ICAL.parse(response.data);
                const root = new ICAL.Component(jcalData);
                const publicHolidays: PublicHoliday[] = root.getAllSubcomponents("vevent").map(vevent => {
                    const event = new ICAL.Event(vevent);
                    const eventDate = event.startDate;
                    return {date: new Date(Date.UTC(eventDate.year, eventDate.month - 1, eventDate.day)), description: event.summary};
                });
                this.setState({ publicHolidays: publicHolidays });
            });
        }
        else this.setState({ publicHolidays: [] });
    }

    fetchHolidaysBackend(userNickname: string) {
        this.holidaysBackend.fetchHolidaysByUser(userNickname).then(personalHolidays => {
            this.setState({ personalHolidays: personalHolidays });
        })
        .catch(error => this.addError(Strings.FETCH_HOLIDAYS_ERROR + ": " + error));
    }

    fetchPrefsBackend(userNickname: string) {
        this.prefsBackend.fetchPrefsByUser(userNickname)
            .then(userPrefs => this.setState({ userPrefs: userPrefs }))
            .catch(error => this.addError(Strings.FETCH_USERPREFS_ERROR + ": " + error));
    }

    addError(error: any) {
        this.setState(state => {
            const errors: string[] = state.errors.concat(error);
            return { errors: errors, loadingSetRequestedHolidays: false, 
                     loadingSetGrantedHolidays: false, loadingDeleteHolidays: false };
        });
    }

    getRemainingHolidays(): number {
        const { year, personalHolidays, selectedDays, userPrefs } = this.state;
        const yearHolidays: number = personalHolidays.filter(holiday => holiday.date.getFullYear() === year).length;
        const yearSelectedDays: number = selectedDays.filter(selectedDay => {
            return selectedDay.getFullYear() === year &&
                   !personalHolidays.find(holiday => holiday.date.getTime() === selectedDay.getTime());
        }).length;
        const yearMaxHolidays: number = userPrefs.yearHolidays[year] || 23;
        return yearMaxHolidays - (yearHolidays + yearSelectedDays);
    }

    handleDayClick(month: number, day: number, selected: boolean) {
        const { year, selectedDays } = this.state;

        const currentDay: Date = new Date(Date.UTC(year, month, day));
        // Day selection
        if (selected) {
            const newSelectedDays: Date[] = [...selectedDays, currentDay];
            this.setState({ selectedDays: newSelectedDays });
        }
        // Day deselection
        else {
            const newSelectedDays: Date[] = selectedDays.filter(selectedDay => {
                return selectedDay.getTime() !== currentDay.getTime();
            });
            this.setState({ selectedDays: newSelectedDays });
        }
    }

    // REQUESTED & GRANTED HOLIDAYS
    handleSetRequestedHolidays() {
        this.setState({ loadingSetRequestedHolidays: true });
        this.setSelectedDaysAsHolidays(false, Strings.SET_REQUESTED_HOLIDAYS_SUCCESS, Strings.SET_REQUESTED_HOLIDAYS_ERROR);
    }

    handleSetGrantedHolidays() {
        this.setState({ loadingSetGrantedHolidays: true });
        this.setSelectedDaysAsHolidays(true, Strings.SET_GRANTED_HOLIDAYS_SUCCESS, Strings.SET_GRANTED_HOLIDAYS_ERROR);
    }

    setSelectedDaysAsHolidays(granted: boolean, successMessage: string, errorMessage: string) {
        const { userId, personalHolidays, selectedDays } = this.state;
        let insertUpdatePromises: Promise<void>[] = [];
        let holidays: PersonalHoliday[] = [];

        selectedDays.forEach(selectedDay => {
            const holiday: PersonalHoliday = new PersonalHoliday(userId, selectedDay, granted);
            if (personalHolidays.find(personalHoliday => { return personalHoliday.equals(holiday) })) {
                insertUpdatePromises.push(this.holidaysBackend.updateHoliday(holiday));
            }
            else insertUpdatePromises.push(this.holidaysBackend.insertHoliday(holiday));
            holidays.push(holiday);
        });
        Promise.all(insertUpdatePromises)
            .then(() => this.setHolidays(holidays, successMessage))
            .catch(error => this.addError(errorMessage + ": " + error));
    }

    setHolidays(newHolidays: PersonalHoliday[], successMessage: string) {
        // Update existing holidays
        let personalHolidays: PersonalHoliday[] = this.state.personalHolidays.map(holiday => {
            const newHoliday: PersonalHoliday = newHolidays.find(newHoliday => { return newHoliday.equals(holiday) });
            return newHoliday ? newHoliday : holiday;
        });
        // Add new holidays
        personalHolidays = personalHolidays.concat(newHolidays.filter(newHoliday => {
            return !this.state.personalHolidays.find(holiday => { return holiday.equals(newHoliday) });
        }));

        this.setState({ personalHolidays: personalHolidays, selectedDays: [], 
                        loadingSetRequestedHolidays: false, loadingSetGrantedHolidays: false,
                        loadingDeleteHolidays: false });
        toast.success(successMessage, {className: "bg-success text-light"});
    }

    // DELETE HOLIDAYS
    handleDeleteHolidays() {
        this.setState({ loadingDeleteHolidays: true });
        const { userId, personalHolidays, selectedDays } = this.state;
        let deletePromises: Promise<void>[] = [];

        selectedDays.forEach(selectedDay => {
            if (personalHolidays.find(personalHoliday => { return personalHoliday.date.getTime() === selectedDay.getTime() })) {
                deletePromises.push(this.holidaysBackend.deleteHoliday(userId, selectedDay));
            } 
        });
        Promise.all(deletePromises)
            .then(() => this.deleteHolidays(selectedDays))
            .catch(error => this.addError(Strings.DELETE_HOLIDAYS_ERROR + ": " + error));
    }

    deleteHolidays(dates: Date[]) {
        const personalHolidays: PersonalHoliday[] = this.state.personalHolidays.filter(holiday => {
            return !dates.find(date => { return date.getTime() === holiday.date.getTime() });
        });
        this.setState({ personalHolidays: personalHolidays, selectedDays: [], 
                        loadingSetRequestedHolidays: false, loadingSetGrantedHolidays: false,
                        loadingDeleteHolidays: false });
        toast.success(Strings.DELETE_HOLIDAYS_SUCCESS, {className: "bg-success text-light"});
    }

    handlePreviousYear() {
        const newYear: number = this.state.year - 1;
        this.setState({ year: newYear, selectedDays: [] });
        this.fetchPublicHolidays(newYear);
    }

    handleNextYear() {
        const newYear: number = this.state.year + 1;
        this.setState({ year: newYear, selectedDays: [] });
        this.fetchPublicHolidays(newYear);
    }

    render() {
        const { year, publicHolidays, personalHolidays, selectedDays } = this.state;
        const { loadingSetRequestedHolidays, loadingSetGrantedHolidays, loadingDeleteHolidays } = this.state;

        const setRequestedHolidaysEnabled: boolean = !!selectedDays.find(selectedDay => {
            return !personalHolidays.find(holiday => { return holiday.date.getTime() === selectedDay.getTime() && !holiday.granted });
        });
        const setGrantedHolidaysEnabled: boolean = !!selectedDays.find(selectedDay => {
            return !personalHolidays.find(holiday => { return holiday.date.getTime() === selectedDay.getTime() && holiday.granted });
        });
        const deleteHolidaysEnabled: boolean = !!selectedDays.find(selectedDay => {
            return !!personalHolidays.find(holiday => { return holiday.date.getTime() === selectedDay.getTime() });
        });
        return (
            <div className="min-vh-100 d-flex flex-column">
                <CSBNavbar/>
                
                <Container fluid className="bg-csb d-flex flex-column flex-grow-1">
                    <Row className="my-2 my-md-3 my-lg-4">
                        <Col className="px-1 px-md-2 px-lg-3">
                            <Card>
                                <Card.Header className="calendar-header">
                                    <CalendarHeader
                                        currentYear={year}
                                        remainingHolidays={this.getRemainingHolidays()}
                                        setRequestedHolidaysEnabled={setRequestedHolidaysEnabled}
                                        setGrantedHolidaysEnabled={setGrantedHolidaysEnabled}
                                        deleteHolidaysEnabled={deleteHolidaysEnabled}
                                        loadingSetRequestedHolidays={loadingSetRequestedHolidays}
                                        loadingSetGrantedHolidays={loadingSetGrantedHolidays}
                                        loadingDeleteHolidays={loadingDeleteHolidays}
                                        onSetRequestedHolidays={this.handleSetRequestedHolidays}
                                        onSetGrantedHolidays={this.handleSetGrantedHolidays}
                                        onDeleteHolidays={this.handleDeleteHolidays}
                                        onPreviousYear={this.handlePreviousYear}
                                        onNextYear={this.handleNextYear}
                                    />
                                </Card.Header>
                                <Card.Body>
                                    <Calendar 
                                        year={year} 
                                        publicHolidays={publicHolidays}
                                        personalHolidays={personalHolidays}
                                        selectedDays={selectedDays}
                                        onDayClick={this.handleDayClick}
                                    />
                                    <CalendarCaption/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Footer/>

                {/* NOTIFICATIONS (Success, confirmation and error) */}
                <DefaultToastContainer/>
                <ErrorModal errors={this.state.errors} onHide={() => this.setState({ errors: [] })}/>
            </div>
        );
    }
}


interface CalendarHeaderProps {
    currentYear: number;
    remainingHolidays: number;
    setRequestedHolidaysEnabled: boolean;
    setGrantedHolidaysEnabled: boolean;
    deleteHolidaysEnabled: boolean;
    loadingSetRequestedHolidays: boolean;
    loadingSetGrantedHolidays: boolean;
    loadingDeleteHolidays: boolean;
    onSetRequestedHolidays: () => void;
    onSetGrantedHolidays: () => void;
    onDeleteHolidays: () => void;
    onPreviousYear: () => void;
    onNextYear: () => void;
}

class CalendarHeader extends Component<CalendarHeaderProps,object> {
    render() {
        const { currentYear, remainingHolidays,
                setRequestedHolidaysEnabled, setGrantedHolidaysEnabled, deleteHolidaysEnabled, 
                loadingSetRequestedHolidays, loadingSetGrantedHolidays, loadingDeleteHolidays,
                onSetRequestedHolidays, onSetGrantedHolidays, onDeleteHolidays, 
                onPreviousYear, onNextYear } = this.props;

        const loadingAction: boolean = loadingSetRequestedHolidays || loadingSetGrantedHolidays || loadingDeleteHolidays;

        return (
            <Row className="align-items-center justify-content-between">
                <Col xl="auto" className="d-none d-xl-block">
                    <h5 className="mb-0">{Strings.CALENDAR_HEADER}</h5>
                </Col>
                {/* REMAINING HOLIDAYS */}
                <Col xs={12} sm={8} lg="auto" className="mb-3 mb-lg-0 text-center">
                    <h6 className="mb-0 mr-2">{Strings.REMAINING_HOLIDAYS}: <strong>{remainingHolidays}</strong></h6>
                </Col>
                {/* CALENDAR ACTIONS */}
                <Col xs={12} sm={{span:8,order:2}} lg={{span:"auto",order:1}} className="mb-3 mb-sm-0">
                    <div className="d-flex align-items-center justify-content-center">
                        <h6 className="mb-0 mr-2">{Strings.SET_HOLIDAY_BTN_GROUP}:</h6>
                        <Button 
                            variant="info" size="sm" className="mr-1" 
                            disabled={!setRequestedHolidaysEnabled || loadingAction}
                            onClick={onSetRequestedHolidays} 
                        >
                            {loadingSetRequestedHolidays ? 
                                <span><i className="fas fa-spinner fa-pulse"></i> {Strings.SET_REQUESTED_HOLIDAYS_BTN_LOADING}</span> 
                                :
                                <span><i className="fas fa-question"></i> {Strings.SET_REQUESTED_HOLIDAYS_BTN}</span>
                            }
                        </Button>
                        <Button 
                            variant="success" size="sm" 
                            disabled={!setGrantedHolidaysEnabled || loadingAction}
                            onClick={onSetGrantedHolidays} 
                        >
                            {loadingSetGrantedHolidays ? 
                                <span><i className="fas fa-spinner fa-pulse"></i> {Strings.SET_GRANTED_HOLIDAYS_BTN_LOADING}</span> 
                                :
                                <span><i className="fas fa-check"></i> {Strings.SET_GRANTED_HOLIDAYS_BTN}</span>
                            }
                        </Button>
                    </div>
                </Col>
                <Col xs={12} sm={{span:4,order:3}} lg={{span:"auto",order:2}} className="mb-3 mb-sm-0 text-center">
                    <Button 
                        variant="danger" size="sm" 
                        disabled={!deleteHolidaysEnabled || loadingAction}
                        onClick={onDeleteHolidays}
                    >
                        {loadingDeleteHolidays ?
                            <span><i className="fas fa-spinner fa-pulse"></i> {Strings.DELETE_HOLIDAYS_BTN_LOADING}</span>
                            :
                            <span><i className="fas fa-times"></i> {Strings.DELETE_HOLIDAYS_BTN}</span>
                        }
                    </Button>
                </Col>
                {/* YEAR SELECTOR */}
                <Col xs={12} sm={{span:4,order:1}} lg={{span:"auto",order:3}} className="mb-sm-3 mb-lg-0 text-center">
                    <ButtonGroup className="align-items-center">
                        <Button variant="light" size="sm" onClick={onPreviousYear}>
                            <i className="fas fa-chevron-left"></i>
                        </Button>
                        <div className="mx-2">{currentYear}</div>
                        <Button variant="light" size="sm" onClick={onNextYear}>
                            <i className="fas fa-chevron-right"></i>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        );
    }
}


class CalendarCaption extends Component {
    render() {
        return (
            <Row className="justify-content-evenly">
                <Col xs={12} sm={{span:6,order:0}} lg={{span:"auto",order:0}} className="d-flex justify-content-center">
                    <div className="caption-element">
                        <i className="fas fa-circle text-danger"></i> {Strings.CAPTION_WEEKENDS}
                    </div>
                </Col>
                <Col xs={12} sm={{span:6,order:2}} lg={{span:"auto",order:1}} className="d-flex justify-content-center">
                    <div className="caption-element">
                        <i className="fas fa-circle text-warning"></i> {Strings.CAPTION_PUBLIC_HOLIDAYS}
                    </div>
                </Col>
                <Col xs={12} sm={{span:6,order:1}} lg={{span:"auto",order:2}} className="d-flex justify-content-center">
                    <div className="caption-element">
                        <i className="fas fa-circle text-info"></i> {Strings.CAPTION_PERSONAL_REQUESTED_HOLIDAYS}
                    </div>
                </Col>
                <Col xs={12} sm={{span:6,order:3}} lg={{span:"auto",order:3}} className="d-flex justify-content-center">
                    <div className="caption-element">
                        <i className="fas fa-circle text-success"></i> {Strings.CAPTION_PERSONAL_GRANTED_HOLIDAYS}
                    </div>
                </Col>
            </Row>
        );
    }
}