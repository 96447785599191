import BackendPartesDB from './BackendPartesDB';
import * as GoogleUserManager from './GoogleUserManager';

import UserPrefs, { UserPrefDB } from '../model/UserPrefs';


export default class PrefsBackend extends BackendPartesDB {

    fetchPrefsByUser(userId: string): Promise<UserPrefs> {
        console.log("fetchPrefsByUser(" + userId + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"tecnic":"' + userId + '"}');
                this.axiosInst.get(encodeURI('/Preferencies?filter=' + filter))
                    .then(response => resolve(UserPrefs.fromJSON(userId, response.data)))
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    updatePrefs(userPrefs: UserPrefs): Promise<void> {
        console.log("updatePrefs(" + userPrefs + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                let insertUpdatePrefsPromises: Promise<void>[] = [];
                userPrefs.toJSON().forEach((userPref: UserPrefDB) => {
                    insertUpdatePrefsPromises.push(this.insertOrUpdatePref(userPref));
                });
                Promise.all(insertUpdatePrefsPromises)
                    .then(() => resolve())
                    .catch((error) => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    private insertOrUpdatePref(userPref: UserPrefDB): Promise<void> {
        return new Promise((resolve, reject) => {
            const rowId: string = userPref.tecnic + "," + userPref.clau;
            this.axiosInst.put(encodeURI('/Preferencies/' + rowId), {valor: userPref.valor})
                .then(() => resolve())
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.axiosInst.post(encodeURI('/Preferencies/'), userPref)
                            .then(() => resolve())
                            .catch(error => reject(error));
                    }
                    else reject(error);
                });
        });
    }

}