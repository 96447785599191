import BackendJiraDB from './BackendJiraDB';
import * as GoogleUserManager from './GoogleUserManager';

import JiraIssue, { JiraIssueDB } from '../model/JiraIssue';


export default class JiraBackend extends BackendJiraDB {

    fetchJiraIssues(): Promise<JiraIssue[]> {
        console.log("fetchJiraIssues()");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.get(encodeURI('/issue?order=id:DESC'))
                    .then(response => {
                        const jiraIssues: JiraIssue[] = response.data.map((jiraIssue: JiraIssueDB) => JiraIssue.fromJSON(jiraIssue));
                        resolve(jiraIssues);
                    })
                    .catch(error => reject(BackendJiraDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }
    
}