import BackendPartesDB from './BackendPartesDB';
import * as GoogleUserManager from './GoogleUserManager';

import Project, { ProjectDB } from '../model/Project';
import Profile, { ProfileDB } from '../model/Profile';
import AllocationType, { AllocationTypeDB } from '../model/AllocationType';
import Parte, { ParteDB } from '../model/Parte';


export default class PartesBackend extends BackendPartesDB {

    fetchUserId(userNickname: string): Promise<number> {
        console.log("fetchUserId(" + userNickname + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"nickname":"' + userNickname + '"}');
                this.axiosInst.get(encodeURI('/Tecnics?filter=' + filter))
                    .then(response => resolve(response.data[0].id))
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    fetchProjects(): Promise<Project[]> {
        console.log("fetchProjects()");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"actiu":"Si"}');
                this.axiosInst.get(encodeURI('/Projectes?filter=' + filter + '&order=id'))
                    .then(response => {
                        const projects: Project[] = response.data.map((project: ProjectDB) => Project.fromJSON(project));
                        resolve(projects);
                    })
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }
    
    filterProjectsByUser(projects: Project[], userId: number): Promise<Project[]> {
        console.log("filterProjectsByUser(" + userId + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"id_tecnic":"' + userId + '"}');
                this.axiosInst.get(encodeURI('/Tecnics_Projectes?filter=' + filter))
                    .then(response => { 
                        const userProjectsIds: number[] = response.data.map((entry: any) => entry.id_projecte); 
                        const projectsByUser: Project[] = projects.filter(project => userProjectsIds.includes(project.id));
                        resolve(projectsByUser);
                    })
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    fetchProfiles(): Promise<Profile[]> {
        console.log("fetchProfiles()");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"actiu":"Si"}');
                this.axiosInst.get(encodeURI('/Perfils?filter=' + filter + '&order=id'))
                    .then(response => {
                        const profiles: Profile[] = response.data.map((profile: ProfileDB) => Profile.fromJSON(profile));
                        resolve(profiles);
                    })
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    fetchAllocationTypes(): Promise<AllocationType[]> {
        console.log("fetchAllocationTypes()");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.get(encodeURI('/TipusImputacio?order=ordre'))
                    .then(response => {
                        const allocationTypes: AllocationType[] = response.data.map((allocationType: AllocationTypeDB) => AllocationType.fromJSON(allocationType));
                        resolve(allocationTypes);
                    })
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }
    
    fetchPartesByUser(userId: number): Promise<Parte[]> {
        console.log("fetchPartesByUser(" + userId + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const filter: string = encodeURIComponent('{"id_tecnic":"' + userId + '","actiu":"Si"}');
                this.axiosInst.get(encodeURI('/Partes?filter=' + filter + '&order=data:DESC,id:DESC'))
                    .then(response => {
                        const partes: Parte[] = response.data.map((parte: ParteDB) => Parte.fromJSON(parte));
                        resolve(partes);
                    })
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

    fetchMaxParteId(): Promise<number> {
        console.log("fetchMaxParteId()");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.get(encodeURI('/Partes?order=id:DESC&limit=1'))
                    .then(response => resolve(response.data[0].id))
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }
    
    insertParte(parte: Parte): Promise<void> {
        console.log("insertParte(" + parte + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.post(encodeURI('/Partes'), parte.toJSON())
                    .then(() => resolve())
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }
    
    updateParte(parte: Parte): Promise<void> {
        console.log("updateParte(" + parte + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                const { id, id_tecnic, actiu, temporal, ...parteFields } = parte.toJSON();
                this.axiosInst.put(encodeURI('/Partes/' + id), parteFields)
                    .then(() => resolve())
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }
    
    deleteParte(parteId: number): Promise<void> {
        console.log("deleteParte(" + parteId + ")");
        return new Promise((resolve, reject) => {
            GoogleUserManager.reloadUserTokenIfExpired().then(() => {
                this.setAuthorizationHeader();
                this.axiosInst.put(encodeURI('/Partes/' + parteId), {actiu: "No"})
                    .then(() => resolve())
                    .catch(error => reject(BackendPartesDB.handleError(error)));
            })
            .catch(error => reject(error));
        });
    }

}