import React, { Component } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import * as DateUtils from '../../common/DateUtils';
import * as Strings from '../../common/Strings';

import Project from '../../model/Project';
import Profile from '../../model/Profile';
import AllocationType from '../../model/AllocationType';
import JiraIssue from '../../model/JiraIssue';
import Parte from '../../model/Parte';

import './PartesListRow.scss';


export interface Props {
    key: number;
    parte: Parte;
    projects: Project[];
    profiles: Profile[];
    allocationTypes: AllocationType[];
    jiraIssues: JiraIssue[];
    loadingUpdate: boolean;
    loadingDelete: boolean;
    onEditParte: (parte: Parte) => void;
    onDeleteParte: (parteId: number) => void;
}

interface State {
    editing: boolean;
    editedParte: Parte;
    showValidation: boolean;
}

export default class PartesListRow extends Component<Props,State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            editing: false,
            editedParte: this.props.parte,
            showValidation: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelEdit = this.handleCancelEdit.bind(this);
    }

    // onChange
    handleInputChange(event: React.ChangeEvent) {
        const { name, value } = event.currentTarget as HTMLInputElement;
        let editedParte: Parte = this.state.editedParte.copy();
        switch (name) {
            case "date": editedParte.date = new Date(value); break;
            case "projectId": editedParte.project.id = Number(value); break;
            case "profileId": editedParte.profile.id = Number(value); break;
            case "allocationType": editedParte.allocationType.id = value; break;
            case "jiraIssue": editedParte.jiraIssue.key = value; break;
            case "hours": editedParte.hours = Number(value); break;
            case "comment": editedParte.comment = value; break;
            default: break;
        }
        this.setState({ editedParte: editedParte });
    }

    // onSubmit
    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        const form: HTMLFormElement = event.currentTarget;
        if (this.validate(form)) {
            this.props.onEditParte(this.state.editedParte.copy());
            this.setState({ editing: false });
        }
    }

    validate(form: HTMLFormElement): boolean {
        const valid: boolean = form.checkValidity();
        this.setState({ showValidation: !valid });
        return valid;
    }

    // onCancelEdit
    handleCancelEdit() {
        this.setState({ 
            editing: false,
            editedParte: this.props.parte,
            showValidation: false
        });
    }

    render() {
        const { parte, projects, profiles, allocationTypes, jiraIssues, loadingUpdate, loadingDelete } = this.props;
        const { editedParte, editing, showValidation } = this.state;
        // DATA ROW
        if (!editing) {
            return (
                <tr>
                    <td className="nowrap-lg">{parte.date.getDate()}</td>
                    <td className="nowrap-lg">{parte.project.description}</td>
                    <td className="nowrap-lg">{parte.profile.description}</td>
                    <td className="d-none d-sm-table-cell nowrap-lg">{parte.allocationType.description}</td>
                    <td className="d-none d-sm-table-cell">
                        {parte.jiraIssue.key ? parte.jiraIssue.key + ' | ' + parte.jiraIssue.summary : Strings.PARTE_JIRA_ISSUE_EMPTY}
                    </td>
                    <td className="nowrap-lg">{parte.hours}</td>
                    <td className="d-none d-sm-table-cell">{parte.comment}</td>
                    
                    {/* Actions */}
                    <td className="fit pt-1 pb-1 text-right">
                        {/* Edit button */}
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={"tooltip-edit-" + parte.id}>
                                    {loadingUpdate ? Strings.EDIT_PARTE_BTN_LOADING : Strings.EDIT_PARTE_BTN}
                                </Tooltip>
                            }
                        >
                            <Button 
                                variant="info" size="sm" className="btn-table mb-1 mb-lg-0 mr-lg-1" 
                                disabled={loadingUpdate || loadingDelete}
                                onClick={() => this.setState({ editing: true })} 
                            >
                                {loadingUpdate ? 
                                    <i className="fas fa-spinner fa-pulse"></i> : 
                                    <i className="fas fa-edit"></i>
                                }                            
                            </Button>
                        </OverlayTrigger>
                        <span className="d-inline d-lg-none"><br/></span>
                        {/* Delete button */}
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={"tooltip-delete-" + parte.id}>
                                    {loadingDelete ? Strings.DELETE_PARTE_BTN_LOADING : Strings.DELETE_PARTE_BTN}
                                </Tooltip>
                            }
                        >
                            <Button 
                                variant="danger" size="sm" className="btn-table" disabled={loadingUpdate || loadingDelete}
                                onClick={() => this.props.onDeleteParte(parte.id)} 
                            >
                                {loadingDelete ?
                                    <i className="fas fa-spinner fa-pulse"></i> : 
                                    <i className="fas fa-trash-alt"></i>
                                }
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            );
        }
        // EDIT ROW
        else {
            const projectOptions: JSX.Element[] = projects.map(project => {
                return <option key={project.id} value={project.id}>{project.description}</option>
            });
            const profileOptions: JSX.Element[] = profiles.map(profile => {
                return <option key={profile.id} value={profile.id}>{profile.description}</option>
            });
            const allocationTypeOptions: JSX.Element[] = allocationTypes.filter(allocationType => allocationType.active).map(allocationType => {
                return <option key={allocationType.id} value={allocationType.id}>{allocationType.description}</option>
            });
            const jiraIssueOptions: JSX.Element[] = jiraIssues.map(jiraIssue => {
                return <option key={jiraIssue.key} value={jiraIssue.key}>{jiraIssue.key} | {jiraIssue.summary}</option>
            });

            return (
                <tr>
                    {/* Date */}
                    <td className={"date" + (showValidation ? " was-validated" : "")}>
                        <Form.Control 
                            type="date" name="date" size="sm" form={"edit" + editedParte.id}
                            value={DateUtils.toISODateString(editedParte.date)} onChange={this.handleInputChange}
                            required max={DateUtils.toISODateString(new Date())}
                        />
                    </td>
                    {/* Project */}
                    <td className={"project" + (showValidation ? " was-validated" : "")}>
                        <Form.Control 
                            as="select" name="projectId" size="sm" form={"edit" + editedParte.id}
                            value={String(editedParte.project.id)} onChange={this.handleInputChange}
                            required
                        >
                            {projectOptions}
                        </Form.Control>
                    </td>
                    {/* Profile */}
                    <td className={"profile" + (showValidation ? " was-validated" : "")}>
                        <Form.Control 
                            as="select" name="profileId" size="sm" form={"edit" + editedParte.id}
                            value={String(editedParte.profile.id)} onChange={this.handleInputChange}
                            required
                        >
                            {profileOptions}
                        </Form.Control>
                    </td>
                    {/* Allocation type */}
                    <td className={"d-none d-sm-table-cell allocationType" + (showValidation ? " was-validated" : "")}>
                        <Form.Control
                            as="select" name="allocationType" size="sm" form={"edit" + editedParte.id}
                            value={editedParte.allocationType.id} onChange={this.handleInputChange}
                            required
                        >
                            {allocationTypeOptions}
                        </Form.Control>
                    </td>
                    {/* Jira issue */}
                    <td className={"d-none d-sm-table-cell jiraIssue" + (showValidation ? " was-validated" : "")}>
                        <Form.Control
                            as="select" name="jiraIssue" size="sm" form={"edit" + editedParte.id}
                            value={editedParte.jiraIssue.key} onChange={this.handleInputChange}
                        >
                            <option value="">{Strings.PARTE_JIRA_ISSUE_EMPTY}</option>
                            {jiraIssueOptions}
                        </Form.Control>
                    </td>
                    {/* Hours */}
                    <td className={"hours" + (showValidation ? " was-validated" : "")}>
                        <Form.Control 
                            type="number" name="hours" size="sm" form={"edit" + editedParte.id}
                            value={String(editedParte.hours)} onChange={this.handleInputChange}
                            required min={0.5} step={0.5}
                        />
                    </td>
                    {/* Comment */}
                    <td className={"d-none d-sm-table-cell" + (showValidation ? " was-validated" : "")}>
                        <Form.Control 
                            type="text" name="comment" size="sm" form={"edit" + editedParte.id}
                            value={editedParte.comment} onChange={this.handleInputChange}
                        />
                    </td>

                    {/* Actions */}
                    <td className="fit pt-1 pb-1 text-right">
                        <Form id={"edit" + editedParte.id} onSubmit={this.handleSubmit} noValidate validated={showValidation}>
                            {/* Confirm edit button */}
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={"tooltip-edit-confirm-" + parte.id}>{Strings.EDIT_PARTE_BTN_CONFIRM}</Tooltip>
                                }
                            >
                                <Button 
                                    variant="success" size="sm" type="submit" form={"edit" + editedParte.id} 
                                    className="btn-table mb-1 mb-lg-0 mr-lg-1"
                                >
                                    <i className="fas fa-check"></i>
                                </Button>
                            </OverlayTrigger>
                            <span className="d-inline d-lg-none"><br/></span>
                            {/* Cancel edit button */}
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={"tooltip-edit-cancel-" + parte.id}>{Strings.EDIT_PARTE_BTN_CANCEL}</Tooltip>
                                }
                            >
                                <Button variant="danger" size="sm" className="btn-table" onClick={this.handleCancelEdit}>
                                    <i className="fas fa-times"></i>
                                </Button>
                            </OverlayTrigger>
                        </Form>
                    </td>
                </tr>
            );
        }
    }
}