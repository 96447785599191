import * as Strings from '../common/Strings';


export default class Backend {
    static handleError(error: any): string {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR RESPONSE");
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return "(" + error.response.status + ") " + error.response.data;
        } 
        else if (error.request) {
            // The request was made but no response was received
            // "error.request" is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
            console.log("ERROR REQUEST");
            console.log(error.request);
            //return error.request.status + " " + error.request.statusText + ": " + error.request.responseText;
            return Strings.BACKEND_CONNECTION_ERROR;
        } 
        else {
            // Something happened in setting up the request that triggered an Error
            console.log("ANOTHER ERROR");
            console.log(error.message);
            return error.message;
        }
    }

}