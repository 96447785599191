/* NAVBAR */
export const NAVBAR_LINK_PARTES: string = "Partes";
export const NAVBAR_LINK_HOLIDAYS: string = "Vacances";
export const NAVBAR_LINK_PAYSLIPS: string = "Nòmines";
export const NAVBAR_LINK_PREFERENCES: string = "Preferències";

/* MONTHS */
export const MONTHS: string[] = ["Gener","Febrer","Març","Abril","Maig","Juny",
                                 "Juliol","Agost","Setembre","Octubre","Novembre","Desembre"];


/****************/
/** LOGIN PAGE **/
/****************/

/* LOGIN */
export const LOGIN_WITH_GOOGLE: string = "Accedir amb Google";


/*****************/
/** PARTES PAGE **/
/*****************/

/* HEADERS */
export const PARTE_FORM_HEADER: string = "Registrar parte";
export const PARTES_LIST_HEADER: string = "Llista de partes";

/* PARTE FIELDS */
export const PARTE_DAY: string = "Dia";
export const PARTE_DATE: string = "Data";
export const PARTE_PROJECT: string = "Projecte";
export const PARTE_PROFILE: string = "Perfil";
export const PARTE_ALLOCATION_TYPE: string = "Tipus imputació";
export const PARTE_JIRA_ISSUE: string = "Tasca Jira";
export const PARTE_HOURS: string = "Hores";
export const PARTE_COMMENT: string = "Comentari";

export const PARTE_JIRA_ISSUE_EMPTY: string = "Sense detall";

/* FORM SELECTS */
export const SELECT_PROJECT: string = "Seleccionar un projecte";
export const SELECT_PROFILE: string = "Seleccionar un perfil";
export const SELECT_ALL_PROJECTS: string = "Tots els projectes";

/* BUTTONS */
export const INSERT_PARTE_BTN: string = "Registrar";
export const INSERT_PARTE_BTN_LOADING: string = "Registrant...";
export const EDIT_PARTE_BTN: string = "Editar";
export const EDIT_PARTE_BTN_LOADING: string = "Editant...";
export const EDIT_PARTE_BTN_CONFIRM: string = "Confirmar";
export const EDIT_PARTE_BTN_CANCEL: string = "Cancel·lar";
export const DELETE_PARTE_BTN: string = "Eliminar";
export const DELETE_PARTE_BTN_LOADING: string = "Eliminant...";

export const CONFIRMATION_MODAL_BTN_OK: string = "Confirmar";
export const CONFIRMATION_MODAL_BTN_CANCEL: string = "Cancel·lar";

/* TABLE CUSTOM ROWS */
export const PARTES_LOADING: string = "Carregant registres...";
export const NO_PARTES: string = "Sense registres";

/* TABLE TOTALS */
export const TOTALS_SELECTOR: string = "Totals";
export const TOTALS_SELECTOR_MONTH: string = "Mes";
export const TOTALS_SELECTOR_WEEK: string = "Setmana";
export const TOTALS_SELECTOR_DAY: string = "Dia";
export const TOTAL_BY_MONTH: string = "Total hores mes";
export const TOTAL_BY_WEEK: string = "Total hores setmana";
export const TOTAL_BY_DAY: string = "Total hores dia";

/* SUCCESS AND ERROR MESSAGES */
export const INSERT_PARTE_SUCCESS: string = "Parte registrat correctament";
export const UPDATE_PARTE_SUCCESS: string = "Parte modificat correctament";
export const DELETE_PARTE_SUCCESS: string = "Parte eliminat correctament";

export const BACKEND_CONNECTION_ERROR: string = "Error establint la connexió amb el servidor.";

export const FETCH_USERID_PROJECTS_PROFILES_ERROR: string = "Error obtenint l'usuari, projectes i perfils";
export const FETCH_USERPROJECTS_PARTES_ERROR: string = "Error obtenint projectes de l'usuari i partes";
export const INSERT_PARTE_ERROR: string = "Error registrant el parte";
export const UPDATE_PARTE_ERROR: string = "Error modificant el parte";
export const DELETE_PARTE_ERROR: string = "Error eliminant el parte";

/* CONFIRMATION MESSAGES */
export const DELETE_PARTE_CONFIRMATION: string = "Segur que voleu eliminar el parte?";

/* FORM VALIDATION MESSAGES */
export const VALUE_MISSING: string = "Camp obligatori";
export const DATE_VALUE_HIGH: string = "La data màxima és avui";
export const HOUR_VALUE_LOW: string = "Mínim mitja hora";


/*******************/
/** HOLIDAYS PAGE **/
/*******************/

/* HEADERS */
export const CALENDAR_HEADER: string = "Calendari";
export const REMAINING_HOLIDAYS: string = "Dies de vacances restants";

/* CALENDAR ACTIONS */
export const SET_HOLIDAY_BTN_GROUP: string = "Marcar";
export const SET_REQUESTED_HOLIDAYS_BTN: string = "Demanades";
export const SET_REQUESTED_HOLIDAYS_BTN_LOADING: string = "Demanant...";
export const SET_GRANTED_HOLIDAYS_BTN: string = "Acceptades";
export const SET_GRANTED_HOLIDAYS_BTN_LOADING: string = "Acceptant...";
export const DELETE_HOLIDAYS_BTN: string = "Eliminar";
export const DELETE_HOLIDAYS_BTN_LOADING: string = "Eliminant...";

/* CALENDAR CAPTION */
export const CAPTION_WEEKENDS: string = "Caps de setmana";
export const CAPTION_PUBLIC_HOLIDAYS: string = "Dies festius";
export const CAPTION_PERSONAL_REQUESTED_HOLIDAYS: string = "Vacances demanades";
export const CAPTION_PERSONAL_GRANTED_HOLIDAYS: string = "Vacances acceptades";

/* DAYS */
export const DAYS_OF_WEEK_ABBR: string[] = ["Dl","Dt","Dc","Dj","Dv","Ds","Dg"];

/* SUCCESS AND ERROR MESSAGES */
export const SET_REQUESTED_HOLIDAYS_SUCCESS: string = "Vacances demanades marcades correctament";
export const SET_GRANTED_HOLIDAYS_SUCCESS: string = "Vacances acceptades marcades correctament";
export const DELETE_HOLIDAYS_SUCCESS: string = "Vacances eliminades correctament";

export const FETCH_HOLIDAYS_ERROR: string = "Error obtenint les vacances";
export const SET_REQUESTED_HOLIDAYS_ERROR: string = "Error marcant les vacances demanades";
export const SET_GRANTED_HOLIDAYS_ERROR: string = "Error marcant les vacances acceptades";
export const DELETE_HOLIDAYS_ERROR: string = "Error eliminant les vacances";


/**********************/
/** PREFERENCES PAGE **/
/**********************/

/* HEADERS */
export const PARTES_PREFS_HEADER: string = "Partes";
export const PARTES_DEFAULT_VALUES_HEADER: string = "Valors per defecte";
export const HOLIDAYS_PREFS_HEADER: string = "Vacances";
export const YEAR_HOLIDAYS_HEADER: string = "Dies de vacances per any";

/* PARTES FORM */
export const PARTE_DEFAULT_PROJECT: string = "Projecte";
export const PARTE_DEFAULT_PROFILE: string = "Perfil";
export const PARTE_DEFAULT_HOURS: string = "Hores";
export const PARTE_DEFAULT_TOTALS: string = "Totals a la llista de partes";

/* PARTES FORM SELECTS */
export const SELECT_NO_PROJECT: string = "Cap projecte";
export const SELECT_NO_PROFILE: string = "Cap perfil";

/* BUTTONS */
export const SAVE_PARTES_PREFS_BTN: string = "Guardar";
export const SAVE_PARTES_PREFS_BTN_LOADING: string = "Guardant...";
export const SAVE_HOLIDAYS_PREFS_BTN: string = "Guardar";
export const SAVE_HOLIDAYS_PREFS_BTN_LOADING: string = "Guardant...";

/* SUCCESS AND ERROR MESSAGES */
export const STORE_PARTES_PREFS_SUCCESS: string = "Preferències guardades correctament";
export const STORE_HOLIDAYS_PREFS_SUCCESS: string = "Preferències guardades correctament";

export const FETCH_USERID_PROJECTS_ERROR: string = "Error obtenint usuari i projectes";
export const FETCH_USERPROJECTS_ERROR: string = "Error obtenint projectes de l'usuari";
export const FETCH_PROFILES_ERROR: string = "Error obtenint perfils";
export const FETCH_USERPREFS_ERROR: string = "Error obtenint preferències de l'usuari";
export const STORE_PARTES_PREFS_ERROR: string = "Error guardant les noves preferències";
export const STORE_HOLIDAYS_PREFS_ERROR: string = "Error guardant les noves preferències";


/*****************/
/** ERROR PAGES **/
/*****************/

export const ERROR_404_HEADER: string = "Ooops!";
export const ERROR_404_TITLE: string = "404 - Pàgina no trobada :(";
export const ERROR_404_DESCRIPTION_1: string = "La pàgina a la qual heu accedit podria haver estat eliminada o canviada de nom.";
export const ERROR_404_DESCRIPTION_2: string = "També és possible que mai hagi existit.";
export const ERROR_404_BUTTON: string = "Tornar a l'inici";

export const FATAL_ERROR_HEADER: string = "Ouch!";
export const FATAL_ERROR_TITLE: string = "Alguna cosa ha anat malament :'(";
export const FATAL_ERROR_DESCRIPTION: string = "Si us plau, poseu-vos en contacte amb l'administrador d'aquesta pàgina fent-li arribar l'error.";
export const FATAL_ERROR_SHOW_DETAILS_BUTTON: string = "Mostrar error";
export const FATAL_ERROR_HIDE_DETAILS_BUTTON: string = "Ocultar error";
