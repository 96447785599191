
// Google
export const GOOGLE_CLIENT_ID: string = "836680240984-6skrp389k1h625j1lhdn0mrt1guahotk.apps.googleusercontent.com";
export const GOOGLE_SCRIPT_SRC: string = "https://apis.google.com/js/api.js";

// Local Storage
export const LS_USER_MAIL: string = "UserMail";
export const LS_USER_NAME: string = "UserName";
export const LS_USER_IMAGE_URL: string = "UserImageUrl";
export const LS_USER_TOKEN: string = "UserToken";
export const LS_USER_TOKEN_EXP: string = "UserTokenExp";
