import React, { Component } from 'react';
import { GoogleLogout } from 'react-google-login';
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Constants from '../../common/Constants';
import * as Strings from '../../common/Strings';

import * as UserManager from '../../storage/UserManager';
import EmployeeBackend from '../../storage/EmployeeBackend';

import './CSBNavbar.scss';
import logo from '../../assets/logo.png';
import Employee from '../../model/Employee';


export interface Props {
    hideLinks?: boolean;
}

interface State {
    employee?: Employee;
}

export default class CSBNavbar extends Component<Props,State> {
    employeeBackend: EmployeeBackend = new EmployeeBackend();

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const userNickname: string = UserManager.getUserNickname();
        this.employeeBackend.fetchEmployeeByNickname(userNickname)
            .then(employee => this.setState({ employee: employee }));
    }

    render() {
        const { employee } = this.state;

        let payslipsLink: JSX.Element;
        if (employee && employee.payslipsUrl) {
            payslipsLink = 
                <a className="nav-link" href={employee.payslipsUrl} target="_blank">
                    <i className="fas fa-file-invoice-dollar fa-fw"></i> {Strings.NAVBAR_LINK_PAYSLIPS.toUpperCase()}
                </a>
        }

        return (
            <Navbar variant="light" bg="light" expand="lg" collapseOnSelect className="py-0 justify-content-between navbar-csb">
                <Navbar.Brand href="/">
                    <img src={logo} alt="CSB Logo" height="45"></img>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbar-nav"/>
                <Navbar.Collapse id="navbar-nav">
                    <Button 
                        as="a" variant="danger" size="sm" className="btn-block-md my-1 my-lg-0 mx-lg-3"
                        href="https://csb-bi.atlassian.net/secure/RapidBoard.jspa?rapidView=23" target="_blank"
                    >
                        <i className="fas fa-bug"></i> Reportar incidència
                    </Button>
                    {!this.props.hideLinks &&
                        <Nav className="mr-auto">
                            <LinkContainer to="/partes">
                                <Nav.Link>
                                    <i className="fas fa-file-chart-line fa-fw"></i> {Strings.NAVBAR_LINK_PARTES.toUpperCase()}
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/holidays">
                                <Nav.Link>
                                    <i className="fas fa-umbrella-beach fa-fw"></i> {Strings.NAVBAR_LINK_HOLIDAYS.toUpperCase()}
                                </Nav.Link>
                            </LinkContainer>
                            {payslipsLink}
                            <LinkContainer to="/preferences">
                                <Nav.Link>
                                    <i className="fas fa-cog fa-fw"></i> {Strings.NAVBAR_LINK_PREFERENCES.toUpperCase()}
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    }
                    <Nav>
                        {UserManager.getUserMail() ? <Logout/> : ""}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

class Logout extends Component {
    onLogoutSuccess() {
        // GoogleUserManager's listener will take care of login success automatically
    }

    render() {
        if (!UserManager.getUserMail()) return (<Redirect to="/"/>);
        return (
            <div className="my-1 my-lg-0 d-flex align-items-center">
                <Image src={UserManager.getUserImageUrl()} height="30px" className="mr-1" roundedCircle /> 
                <span className="mr-2">{UserManager.getUserName()}</span>

                <GoogleLogout
                    clientId={Constants.GOOGLE_CLIENT_ID}
                    onLogoutSuccess={() => this.onLogoutSuccess()}
                    render={renderProps =>
                        <Button variant="danger" size="sm" onClick={renderProps.onClick}>
                            <i className="fas fa-sign-out-alt"></i>
                        </Button>
                    }
                />
            </div>
        )
    }
}