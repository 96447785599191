import React, { Component } from 'react';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import { Card, Badge } from 'react-bootstrap';

import * as Constants from '../../common/Constants';
import * as Strings from '../../common/Strings';

import './LoginCard.css';
import logo from '../../assets/partes.png'


export default class LoginCard extends Component {
    onLoginSuccess(response: GoogleLoginResponse) {
        // GoogleUserManager's listener will take care of login success automatically
    }

    onLoginFailure(response: any) {
        console.log("Login Failed");
        console.log(response.error + " " + response.details);
    }

    render() {
        return (
            <Card className="login-card text-center">
                <Card.Body className="pt-2">
                    <div className="d-flex align-items-center">
                        <img src={logo} alt="Partes logo" width="90%" className="mb-2"/>
                        <h5 className="align-self-center"><Badge variant="primary">BETA</Badge></h5>
                    </div>

                    <GoogleLogin
                        clientId={Constants.GOOGLE_CLIENT_ID}
                        buttonText={Strings.LOGIN_WITH_GOOGLE}
                        theme="dark"
                        prompt="select_account"
                        onSuccess={(response: GoogleLoginResponse) => this.onLoginSuccess(response)}
                        onFailure={(response: any) => this.onLoginFailure(response)}
                    />
                </Card.Body>
            </Card>
        );
    }
}