import Project from "./Project";
import Profile from "./Profile";
import AllocationType from "./AllocationType";
import JiraIssue from "./JiraIssue";
import * as DateUtils from '../common/DateUtils';


export type ParteDB = {
    id: number;
    id_tecnic: number;
    data: string;
    id_projecte: number;
    id_perfil: number;
    hores: number;
    comentari: string;
    actiu: string;
    temporal: string;
    tipus_imputacio: string;
    tasca_jira: string;
}

export default class Parte {
    id: number;
    userId: number;
    date: Date;
    project: Project;
    profile: Profile;
    allocationType: AllocationType;
    jiraIssue: JiraIssue;
    hours: number;
    comment: string;

    constructor(date: Date, project: Project, profile: Profile, allocationType: AllocationType, jiraIssue: JiraIssue, hours: number, comment: string, id?: number, userId?: number) {
        this.id = id;
        this.userId = userId;
        this.date = date;
        this.project = project;
        this.profile = profile;
        this.allocationType = allocationType;
        this.jiraIssue = jiraIssue;
        this.hours = hours;
        this.comment = comment;
    }

    static fromJSON(parteJSON: ParteDB): Parte {
        const project = new Project(parteJSON.id_projecte);
        const profile = new Profile(parteJSON.id_perfil);
        const allocationType = new AllocationType(parteJSON.tipus_imputacio);
        const jiraIssue = new JiraIssue(parteJSON.tasca_jira);
        return new Parte(new Date(parteJSON.data), project, profile, allocationType, jiraIssue, parteJSON.hores, parteJSON.comentari, parteJSON.id, parteJSON.id_tecnic);
    }

    toJSON(): ParteDB {
        return {id: this.id, id_tecnic: this.userId, data: DateUtils.toISODateString(this.date), id_projecte: this.project.id, 
                id_perfil: this.profile.id, tipus_imputacio: this.allocationType.id, tasca_jira: this.jiraIssue.key, hores: this.hours, 
                comentari: this.comment, actiu: "Si", temporal: "Si"};
    }

    copy(): Parte {
        return new Parte(new Date(this.date), this.project.copy(), this.profile.copy(), this.allocationType.copy(), this.jiraIssue.copy(), 
                         this.hours, this.comment, this.id, this.userId);
    }

    fillDescriptions(projects: Project[], profiles: Profile[], allocationTypes: AllocationType[], jiraIssues: JiraIssue[]): void {
        this.project.description = projects.find(project => { return project.id === this.project.id }).description;
        this.profile.description = profiles.find(profile => { return profile.id === this.profile.id }).description;
        this.allocationType.description = allocationTypes.find(allocationType => { return allocationType.id === this.allocationType.id }).description;
        const jiraIssue = jiraIssues.find(jiraIssue => { return jiraIssue.key === this.jiraIssue.key });
        this.jiraIssue.summary = jiraIssue ? jiraIssue.summary : undefined;
    }
    
}